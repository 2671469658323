import { AppText, CurrencyInput, SelectBottomCustom, SwitchButton } from "components";
import { SelectBottomCustomItem } from "components/InputCustom/SelectBottomCustom";
import { useMasterData } from "contexts/MasterDataContext";
import React, { FC, useEffect, useMemo } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormGetValues, UseFormWatch } from "react-hook-form/dist/types/form";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { ExpenseFormValues } from "screens/Expense/components/ExpenseForm/types";
import { ReportFormValues } from "screens/Report/components/CreateOrEditForm/index.props";
import { Fonts } from "theme";
import { startLayoutAnimation } from "utils";

interface CurrencySectionProps {
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  setValue: UseFormSetValue<ExpenseFormValues | ReportFormValues>;
  watch: UseFormWatch<ExpenseFormValues | ReportFormValues>;
  errors?: FieldErrors<ExpenseFormValues | ReportFormValues>;
  getValues?: UseFormGetValues<ExpenseFormValues | ReportFormValues>;
  /**@default true */
  enableToggle?: boolean;
}
const CurrencySection: FC<CurrencySectionProps> = ({
  setValue,
  watch,
  control,
  errors,
  getValues,
  enableToggle = true,
}) => {
  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  const { setting } = useMasterData();
  const isToggle = watch("currency.isOn");

  const currencyOptions: SelectBottomCustomItem[] = useMemo(() => {
    return setting?.supportedCurrencies?.map((item) => ({ label: item, value: item }));
  }, [setting]);

  useEffect(() => {
    setValue("currency.name", currencyOptions?.[0]?.value as string);
  }, [currencyOptions, setValue]);

  const handleToggle = (value: boolean) => {
    startLayoutAnimation();
    setValue("currency.isOn", value);
    setValue("currency.exchangeRate", value ? 1 : undefined);
  };

  return (
    <View>
      <View style={styles.titleContainer}>
        <AppText style={Fonts.SentenceSubtileXLarge}>{t("currency")}</AppText>
        <SwitchButton value={isToggle} onValueChange={handleToggle} disabled={!enableToggle} />
      </View>
      {isToggle && (
        <View style={styles.inputContainer}>
          <SelectBottomCustom
            snapPoints={[300]}
            style={styles.flex}
            label={t("currency")}
            name="currency.name"
            control={control}
            options={currencyOptions}
            rules={{ required: t("required") }}
            error={errors?.currency?.name}
          />
          <CurrencyInput
            style={styles.flex}
            name="currency.exchangeRate"
            placeholder="1"
            control={control}
            rules={{
              required: t("required"),
            }}
            label={t("exchange_rate")}
            error={errors?.currency?.exchangeRate}
            onChange={(value) => {
              setValue("amount", (value * getValues("foreignTotalAmountWithVat")) as unknown as string, {
                shouldValidate: true,
              });
            }}
          />
        </View>
      )}
    </View>
  );
};
export default CurrencySection;

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputContainer: { flexDirection: "row", gap: 10, marginTop: 10, justifyContent: "space-between" },
  flex: { flex: 1 },
});
