import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { bootstrapAppData } from "app/utils/bootstrap";
import { useAsync } from "app/hooks/useAsync";
import { FullPageSpinner } from "app/components/Lib";
import * as authClient from "app/utils/authClient";
import { Keys } from "app/constants/Keys";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import * as Device from "expo-device";
import AuthHelper from "utils/authHelper";
import { widgetService } from "services/WidgetService";
import { generateEmployeeVatQR } from "utils/generateBizziQR";
import { analyticService } from "services/AnalyticsService";
import { sentryService } from "services/SentryService";
import { isEmpty } from "lodash";
import * as SplashScreen from "expo-splash-screen";
import { getCurrentCompanyId, getCurrentUser, saveCurrentCompanyId, saveCurrentUser } from "utils";
import { ToastManager } from "components";
import i18n from "i18next";

interface IAuthContext {
  user: authClient.TUserInfo;
  login: (account: { username: string; password: string; challengeId?: string; answer?: string }) => Promise<void>;
  logout: () => Promise<void>;
  selectCompany: (companyId: string) => Promise<void>;
  register: () => void;
  forgetPassword: () => void;
  setUserGroup: () => void;
  updateUser: (params: any) => void;
  changePassword: (objects: { old_password: string; new_password: string; user_id: string }) => Promise<void>;
  groupId: string;
  company: Companies;
  receiveInvoiceEmail: string;
  setUserData: ({ token, refreshToken }: { token: string; refreshToken?: string }) => void;
  refreshDataUser: () => Promise<void>;
  switchCompany: (companyId: string) => void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

AuthContext.displayName = "AuthContext";

function AuthProvider(props: any) {
  const { run } = useAsync();
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState<Partial<authClient.TUserInfo>>({});

  const handleFetchUserInfo = async () => {
    const userInfoStorage = await getCurrentUser();
    if (userInfoStorage) {
      setUser(userInfoStorage);
      const currentCompanyId = await getCurrentCompanyId();
      saveCurrentCompanyId(currentCompanyId ?? userInfoStorage?.company?.company_id);
      setLoading(false);
      setTimeout(() => {
        SplashScreen.hideAsync();
      }, 250);
    }
    const data = await run(bootstrapAppData());
    setTimeout(() => {
      SplashScreen.hideAsync();
    }, 250);
    /*-- in the case role from current token not equal current role of user OR role of token is "anonymous" --> logout --*/
    if (data?.user && data?.user?.tokenRole === data?.user?.group?.role) {
      updateUser(data.user);
    } else if (user?.employee_id || data?.user?.tokenRole === "anonymous") {
      updateUser(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleFetchUserInfo();
  }, []);

  useEffect(() => {
    /*-- handle logout when refresh token expired --*/
    AuthHelper.getInstance().configure({ setUserData: setUser });
  }, [setUser]);

  const updateUser = useCallback(
    (user) => {
      setUser(user);
      saveCurrentUser(user);
    },
    [setUser]
  );

  const login = useCallback(
    ({ username = "", password, challengeId, answer }) =>
      authClient
        .login({ challengeId, answer, password, username: username.toLowerCase() })
        .then(async ({ token, refreshToken }) => {
          await AsyncStorage.setItem(Keys.token, token);
          await AsyncStorage.setItem(Keys.refreshToken, refreshToken);

          const user = await authClient.getUser();
          if (Platform.OS !== "web") {
            const isAvailableAsync = await SecureStore.isAvailableAsync();
            if (isAvailableAsync && Device.isDevice && user?.id) {
              await SecureStore.setItemAsync(Keys.USER_FULLNAME, user.fullname);
              await SecureStore.setItemAsync(Keys.USERNAME, username);
              await SecureStore.setItemAsync(Keys.USER_PASSWORD, password);
            }
          }
          updateUser(user);
          return user;
        }),
    [updateUser]
  );

  const register = useCallback(
    (userObject) =>
      authClient.register(userObject).then(async (res) => {
        await AsyncStorage.setItem(Keys.token, res.token);
        const user = await authClient.getUser();
        if (Platform.OS !== "web") {
          const isAvailableAsync = await SecureStore.isAvailableAsync();
          if (isAvailableAsync && Device.isDevice) {
            await SecureStore.setItemAsync(Keys.USER_FULLNAME, user ? user.fullname : "");
            await SecureStore.setItemAsync(Keys.USERNAME, userObject.email);
            await SecureStore.setItemAsync(Keys.USER_PASSWORD, userObject.password);
          }
        }
        setUser(user);
      }),
    [setUser]
  );

  const selectCompany = useCallback(
    async (companyId) => {
      await saveCurrentCompanyId(companyId);
      const user = await authClient.getUser();
      const companyEmployee = user?.employees?.find((emp) => emp.company_id === companyId) ?? ({} as CompanyEmployees);
      if (companyEmployee?.employee_id) {
        updateUser({
          ...user,
          company: companyEmployee.company,
          employee_id: companyEmployee.employee_id,
          is_owner: companyEmployee.is_owner ?? false,
          employee: companyEmployee as CompanyEmployees,
          phone: companyEmployee.phone as string | undefined,
          department: companyEmployee.department,
          expense_email: companyEmployee.expense_email,
          invoice_email: companyEmployee.expense_email,
        });
      } else {
        throw new Error();
      }
    },
    [updateUser]
  );

  const switchCompany = useCallback(
    (companyId) => {
      if (user?.company?.company_id !== companyId) {
        const switchedEmployee = user?.employees?.find((item) => item?.company?.company_id === companyId);
        if (switchedEmployee) {
          ToastManager.success(
            i18n.t("common:switch_company", {
              companyName: switchedEmployee?.company?.name,
            })
          );
          selectCompany(companyId);
        }
      }
    },
    [selectCompany, user]
  );

  const setUserData = useCallback(
    async ({ token, refreshToken }) => {
      await AsyncStorage.setItem(Keys.token, token);
      if (refreshToken) {
        await AsyncStorage.setItem(Keys.refreshToken, refreshToken);
      }

      const user = await authClient.getUser();
      updateUser(user);
    },
    [updateUser]
  );

  const refreshDataUser = useCallback(async () => {
    const user = await authClient.getUser();
    if (Platform.OS !== "web") {
      const isAvailableAsync = await SecureStore.isAvailableAsync();
      if (isAvailableAsync && Device.isDevice && user?.id) {
        await SecureStore.setItemAsync(Keys.USER_FULLNAME, user.fullname);
      }
    }
    updateUser(user);
  }, [updateUser]);

  const forgetPassword = useCallback(async (email) => await authClient.forgetPassword(email), []);

  const changePassword = useCallback(({ old_password, new_password, user_id }) => {
    return authClient.changePassword({ old_password, new_password, user_id });
  }, []);

  const logout = useCallback(async () => {
    await AsyncStorage.setItem(Keys.RECENT_CHOOSE_APPROVAL, JSON.stringify([]));

    return await authClient.logout().then(() => {
      updateUser({});
    });
  }, [setUser]);

  const setUserGroup = useCallback(
    (user) => {
      setUser(user);
    },
    [setUser]
  );

  const getCompanyByExpenseCode = useCallback(async ({ code, email }) => {
    return await authClient.getCompanyByExpenseCode({ code, email });
  }, []);

  const requestOTPFromEmail = useCallback(async (variables) => {
    return await authClient.requestOTPFromEmail(variables);
  }, []);

  const verifyOTP = useCallback(async (variables) => {
    return await authClient.verifyOTP(variables);
  }, []);

  const initializeWidget = async () => {
    const token = await AsyncStorage.getItem(Keys.token);
    const refreshToken = await AsyncStorage.getItem(Keys.refreshToken);
    const employeeId = user.employee?.employee_id;
    const phoneNumber = user.phone;
    const qrCode = generateEmployeeVatQR({ employeeId, phoneNumber });
    await widgetService.init({ token, refreshToken });
    if (user?.company?.company_id) {
      await widgetService.setUser({
        companyInfo: {
          companyId: user.company.company_id,
          qrCode,
          companyName: user.company?.name ?? "",
          email: user.expense_email ?? "",
          taxCode: user.company?.tax_code ?? "",
          address: user.company?.address ?? "",
        },
        role: user.group.role,
      });
    }
    widgetService.reloadContent();
  };
  /*-- setup sentry, analytics and widget service --*/
  useEffect(() => {
    if (isEmpty(user)) {
      sentryService.logout();
      analyticService.logout();
      return;
    }
    const employeeId = user.employee?.employee_id;
    sentryService.setUser({ id: user?.id, email: user?.employee?.email, username: user?.username });
    analyticService.setUser({
      id: user.id,
      username: user.username,
      email: user.email,
      fullname: user.fullname,
      employeeId,
      companyId: user.company?.company_id,
      companyName: user.company?.name,
    });
    initializeWidget();
  }, [user]);
  /*---- end ----*/

  const groupId = useMemo(() => user?.group_id ?? "", [user]);
  const company = useMemo(() => user?.company ?? {}, [user]);
  const receiveInvoiceEmail = useMemo(() => user?.invoice_email ?? "", [user]);

  const value = useMemo(
    () => ({
      login,
      logout,
      register,
      forgetPassword,
      setUserGroup,
      updateUser,
      user,
      groupId,
      company,
      receiveInvoiceEmail,
      changePassword,
      getCompanyByExpenseCode,
      requestOTPFromEmail,
      verifyOTP,
      setUserData,
      refreshDataUser,
      selectCompany,
      switchCompany,
    }),
    [
      login,
      logout,
      register,
      forgetPassword,
      setUserGroup,
      updateUser,
      user,
      groupId,
      company,
      receiveInvoiceEmail,
      changePassword,
      getCompanyByExpenseCode,
      requestOTPFromEmail,
      verifyOTP,
      refreshDataUser,
      setUserData,
      selectCompany,
      switchCompany,
    ]
  );
  if (isLoading) {
    return <FullPageSpinner />;
  }
  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
