import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import groupBy from "lodash/groupBy";

import { Colors } from "theme";
import { useValidateExpenseReportAmountMutation } from "hooks/useValidateExpenseReportAmount";
import { useAuth } from "contexts/AuthContext";
import ExpenseGroupedByRequestAndBudgetListSection from "screens/Report/components/CreateOrEditForm/ExpenseGroupedByRequestAndBudgetListSection";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

const ExpenseGroupedByCategoryTabView = ({
  validateAmount,
  setValidateAmount,
  isValidateBudget = false,
  expenseList,
  handleRemoveExpense,
  handleRemoveRequest,
  onRefreshData,
  enablePartialApproval = false,
  foreignExchangeRate = undefined,
}) => {
  const { company } = useAuth();
  const [validateExpenseReportAmount] = useValidateExpenseReportAmountMutation();
  const [expenseListGroupByBudget, setExpenseListGroupByBudget] = useState([]);

  useEffect(() => {
    if (isEmpty(expenseList)) {
      return;
    }

    const expensesGroupByDate = groupBy(expenseList, ({ expenseDate }) =>
      dayjs(expenseDate).add(7, "hours").format("MM/YYYY")
    );

    const data = Object.keys(expensesGroupByDate).map((expenseDate) => {
      const dataGroupByCategory = groupBy(
        expensesGroupByDate[expenseDate],
        (expense) => expense?.expenseCategory?.expenseCategoryId
      );
      return {
        title: expenseDate,
        groupedExpenses: Object.keys(dataGroupByCategory).map((categoryId) => {
          return {
            categoryId: dataGroupByCategory[categoryId][0]?.expenseCategory?.expenseCategoryId,
            expenses: dataGroupByCategory[categoryId],
          };
        }),
      };
    });
    setExpenseListGroupByBudget(data);
    if (isValidateBudget && expenseList?.length > 0) {
      handleValidateExpenseAmount();
    }
  }, [expenseList]);
  const handleValidateExpenseAmount = async () => {
    const expenseParamsValidate = expenseList
      .filter((item) => item.totalAmountWithoutVat > 0)
      .map((item) => ({
        expenseDate: item.expenseDate,
        spendingAmount: item.totalAmountWithoutVat,
        spendingAmountWithVat: item?.totalAmountWithVat,
        spendingAmountWithoutVat: item?.totalAmountWithoutVat,
        expenseCategoryId: item?.expenseCategory?.expenseCategoryId,
        expenseRequestId: item.expenseRequestId ?? undefined,
        companyTeamId: item.companyTeamId ?? undefined,
      }));
    const rs = await validateExpenseReportAmount({
      variables: {
        input: {
          companyId: company.company_id,
          expenses: expenseParamsValidate,
        },
      },
    });
    setValidateAmount(rs?.data?.validateExpenseReportAmount?.budgets ?? []);
  };

  return (
    <View style={styles.container}>
      <ExpenseGroupedByRequestAndBudgetListSection
        groupBy="budget"
        requestValidateReportAmount={validateAmount}
        expenses={expenseListGroupByBudget}
        handleRemoveExpense={handleRemoveExpense}
        handleRemoveRequest={handleRemoveRequest}
        onRefreshData={onRefreshData}
        enablePartialApproval={enablePartialApproval}
        foreignExchangeRate={foreignExchangeRate}
      />
    </View>
  );
};
export default ExpenseGroupedByCategoryTabView;
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
  },
});
