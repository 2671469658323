import { forwardRef, memo, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Platform, SectionBase, StyleSheet, TouchableOpacity, View } from "react-native";

import { EmptyExpense } from "assets/images/svg/icons";
import {
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  Button,
  CurrencyText,
  EmptyData,
} from "components";
import { CONSTANTS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import { Colors, Fonts } from "theme";
import useExpenseUnClaimForReportQuery from "../../../hooks/useExpenseUnClaimForReportQuery";
import ExpenseSelectItem from "./ExpenseSelectItem";
import ExpenseSelectSection, { SectionCheckboxStatus } from "./ExpenseSelectSection";
import { useNavigation } from "@react-navigation/native";
import SCREEN_NAME from "navigation/ScreenName";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { StackNavigation } from "navigation/type";
import { ChooseExpenseListModalProps, ChooseExpenseListModalMethods } from "../index.props";

const PAGE_SIZE = 100;
const OPTIONS_FILTER_EXPENSE = [
  { label: "all_expense", value: "all" },
  { label: "expense_have_request", value: "have_request" },
  { label: "expense_not_request", value: "none_request" },
];
const ChooseExpenseListModal = forwardRef<ChooseExpenseListModalMethods, ChooseExpenseListModalProps>(
  ({ setExpenseList, hasCashAdvance = false, companyTeam }, ref) => {
    const {
      user: {
        employee: { employee_id: employeeId },
      },
    } = useAuth();
    useImperativeHandle(ref, () => ({
      present: (companyTeamId: null | string, expenseList: Expenses[]) => {
        bottomSheetRef?.current?.present?.();
        setExpenseListSelect(expenseList);
        setCompanyTeamId(companyTeamId || null);
        refetch?.({
          where: {
            employeeId,
            companyTeamId: companyTeamId || null,
            hasCashAdvance,
          },
          limit: PAGE_SIZE,
          offset: 0,
        });
      },
    }));
    const navigation = useNavigation<StackNavigation>();
    const { t } = useTranslation("app/screens/Report/components/CreateOrEditForm");
    const [currentFilter, setCurrentFilter] = useState("all");

    const [companyTeamId, setCompanyTeamId] = useState<string>();
    const [_, { data, loading, called, refetch }] = useExpenseUnClaimForReportQuery({
      where: {
        employeeId,
        companyTeamId,
        hasCashAdvance,
      },
      limit: PAGE_SIZE,
      offset: 0,
    });
    const expenses = useMemo(() => {
      if (currentFilter === "have_request") {
        return data?.expAssignableExpenseReportExpenses?.expenses?.filter((item) => item?.expenseRequestId);
      } else if (currentFilter === "none_request") {
        return data?.expAssignableExpenseReportExpenses?.expenses?.filter((item) => !item?.expenseRequestId);
      }
      return data?.expAssignableExpenseReportExpenses.expenses;
    }, [data, currentFilter]);
    const sections = useMemo(() => {
      const groupedExpenses = expenses
        ?.sort((a, b) => {
          if (a.expenseRequest && !b.expenseRequest) {
            return -1;
          }
          if (!a.expenseRequest && b.expenseRequest) {
            return 1;
          }
          return 0;
        })
        ?.reduce((acc, expense) => {
          const { expenseRequest } = expense;
          let groupName = t("empty_approved_expense_request");
          if (expenseRequest) {
            const { docSequence, title } = expenseRequest;
            groupName = `${docSequence} - ${title}`;
          }

          const group = acc[groupName];
          if (!group) {
            acc[groupName] = {
              title: groupName,
              data: [expense],
            };

            return acc;
          }

          group.data.push(expense);
          return acc;
        }, {});
      return groupedExpenses ? [...Object.values(groupedExpenses)] : [];
    }, [expenses]) as SectionBase<any, unknown>[];

    const hasExpense = Boolean(expenses?.length);
    const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
    const [expenseListSelect, setExpenseListSelect] = useState([]);
    const isRadioSelect = hasCashAdvance;
    const handleSelectSection = (section) => {
      const sectionData = section?.data ?? [];
      if (!isRadioSelect) {
        const allItemChecked = sectionData.every((s) => expenseListSelect.find((e) => e.expenseId === s.expenseId));
        if (allItemChecked) {
          const sectionExpenseIds = sectionData.map((s) => s.expenseId);
          setExpenseListSelect(expenseListSelect.filter((x) => !sectionExpenseIds.includes(x.expenseId)));
        } else {
          const selectedExpensesSet = [...expenseListSelect, ...sectionData].reduce((acc, expense) => {
            const expenseId = expense.expenseId;
            acc[expenseId] = expense;
            return acc;
          }, {});

          setExpenseListSelect(Object.values(selectedExpensesSet));
        }
        return;
      }
      const selectedExpensesSet = sectionData.reduce((acc, expense) => {
        const expenseId = expense.expenseId;
        acc[expenseId] = expense;
        return acc;
      }, {});
      setExpenseListSelect(Object.values(selectedExpensesSet));
    };
    const handleNavigateDetail = (expenseId: string) => () => {
      navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, { expenseId });
    };

    const handleSelectExpense = (item) => () => {
      const isChecked = expenseListSelect.find((i) => i?.expenseId === item?.expenseId);
      let newExpenseListSelect = [...expenseListSelect];
      if (!isChecked) {
        newExpenseListSelect.push(item);
      } else {
        newExpenseListSelect = expenseListSelect.filter((i) => i?.expenseId !== item?.expenseId);
      }
      setExpenseListSelect(newExpenseListSelect);
    };
    const handleChoose = () => {
      bottomSheetRef?.current?.close();
      setExpenseList(expenseListSelect);
    };

    const renderSectionHeader = ({ section }) => {
      const sectionData = section?.data ?? [];
      let checkboxStatus;
      if (!checkboxStatus && sectionData.every((s) => expenseListSelect.find((e) => e.expenseId === s.expenseId))) {
        checkboxStatus = SectionCheckboxStatus.ALL;
      }

      if (!checkboxStatus && sectionData.every((s) => !expenseListSelect.find((e) => e.expenseId === s.expenseId))) {
        checkboxStatus = SectionCheckboxStatus.NONE;
      }

      if (!checkboxStatus) {
        checkboxStatus = SectionCheckboxStatus.SOME;
      }
      return (
        <ExpenseSelectSection
          isRadioSelect={isRadioSelect}
          status={checkboxStatus}
          section={section}
          handleSelectExpenseSection={handleSelectSection}
          renderItem={renderItem}
        />
      );
    };

    const renderItem = ({ item }) => {
      const isChecked = expenseListSelect.find((i) => i?.expenseId === item?.expenseId);
      return (
        <ExpenseSelectItem
          disabled={hasCashAdvance}
          isChecked={isChecked}
          handleSelectExpense={handleSelectExpense}
          handleNavigateDetail={handleNavigateDetail}
          item={item}
        />
      );
    };

    const totalAmountWithVat = useMemo(() => {
      return expenseListSelect.reduce((total: number, item) => total + item?.totalAmountWithVat, 0);
    }, [expenseListSelect]);

    const renderFooter = () => {
      if (!hasExpense || loading) {
        return null;
      }

      return (
        <View style={[styles.footer, { paddingBottom: bottomInset }]}>
          <View>
            <AppText style={Fonts.BodyMedium} color={Colors.grayscale60}>
              {t("choose_expense_count", { num: expenseListSelect?.length })}
            </AppText>
            <CurrencyText style={Fonts.SentenceSubtileXLarge} color={Colors.success100}>
              {totalAmountWithVat}
            </CurrencyText>
          </View>
          <Button style={styles.chooseButton} onPress={handleChoose} disabled={expenseListSelect?.length === 0}>
            {t("confirm")}
          </Button>
        </View>
      );
    };
    const navigateToCreateExpense = () => {
      navigation.navigate(SCREEN_NAME.NewExpenseScreen, {
        companyTeam,
        isGoBackAfterCreated: true,
        requireExpenseIsReady: true,
        onRefreshData: refetch,
      });
    };

    const renderListEmptyComponent = () => {
      if (loading && !called) {
        return null;
      }
      return (
        <EmptyData
          title={t("empty_expense")}
          icon={<EmptyExpense />}
          button={
            <Button style={styles.createExpenseButton} onPress={navigateToCreateExpense}>
              {t("create_expense")}
            </Button>
          }
        />
      );
    };
    const handleSelectFilter = (value) => () => {
      setCurrentFilter(value);
    };
    const renderHeaderList = () => {
      return (
        <View style={styles.filterStatusHeader}>
          {OPTIONS_FILTER_EXPENSE.map((item) => {
            const isActive = currentFilter === item.value;

            return (
              <TouchableOpacity
                onPress={handleSelectFilter(item?.value)}
                key={item.value}
                style={[styles.statusButton, isActive && { borderColor: Colors.primary50 }]}
              >
                <AppText style={Fonts.BodySmall} color={isActive ? Colors.primary50 : Colors.grayscale100}>
                  {t(item.label)}
                </AppText>
              </TouchableOpacity>
            );
          })}
        </View>
      );
    };
    const renderSection = () => {
      return (
        <>
          {sections.map((section, index) => {
            return (
              <View style={styles.section} key={index}>
                {renderSectionHeader({ section })}
              </View>
            );
          })}
          {!sections.length && renderListEmptyComponent()}
        </>
      );
    };
    const { bottom } = useSafeAreaInsets();
    const bottomInset = bottom || 15;
    return (
      <BottomSheetScrollViewModalCustom
        title={hasCashAdvance ? t("add_expense_cash_advance") : t("add_expense")}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        ref={bottomSheetRef}
        containerStyle={styles.expenseListContent}
        renderFooter={renderFooter}
        footerStyle={styles.footerContainer}
        renderHeaderList={renderHeaderList}
      >
        {loading ? <ActivityIndicator style={styles.loading} size="small" color={Colors.primary50} /> : renderSection()}
      </BottomSheetScrollViewModalCustom>
    );
  }
);
export default memo(ChooseExpenseListModal);

const styles = StyleSheet.create({
  expenseListContent: {
    paddingBottom: Platform.OS === "web" ? 180 : 90,
  },
  footerContainer: {
    paddingHorizontal: 0,
    paddingBottom: 0,
  },
  footer: {
    flexDirection: "row",
    backgroundColor: Colors.white,
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    ...CONSTANTS.COMMON.SHADOW_DEFAULT,
  },
  chooseButton: { minWidth: 121 },
  loading: { marginTop: 50 },
  section: {
    marginBottom: 16,
  },
  filterStatusHeader: {
    flexDirection: "row",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
    gap: 8,
  },
  statusButton: {
    height: 32,
    justifyContent: "center",
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.grayscale10,
    borderRadius: 999,
  },
  createExpenseButton: { minWidth: 100, alignSelf: "center", paddingHorizontal: 20 },
});
