// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Toast, WhiteSpace } from "@ant-design/react-native";
import { useNavigation } from "@react-navigation/native";
import { Formik, FormikProps } from "formik";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Image, Keyboard, Platform, StatusBar, StyleSheet, View } from "react-native";
import { Dialog, Paragraph, Portal, Text } from "react-native-paper";
import * as Yup from "yup";

import { DEV_INITIAL_USER_EMAIL, DEV_INITIAL_USER_PASSWORD } from "@env";
import { BizziBotLogin } from "assets/images";
import { BiometricsIcon, IrisIcon } from "assets/images/svg/icons";
import FaceIdIcon from "assets/images/svg/icons/FaceId";
import FingerPrint from "assets/images/svg/icons/FingerPrint";
import { AppText, Button, IconCustom, TextInput, TouchableOpacityCustom, ToastManager } from "components";
import { CONSTANTS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import useLocalAuthLogin from "hooks/useLocalAuthLogin";
import useSupportedAuthenticationTypes from "hooks/useSupportedAuthenticationTypes";
import isString from "lodash/isString";
import toLower from "lodash/toLower";
import { default as SCREEN_NAME, default as ScreenName } from "navigation/ScreenName";
import { LoginScreenNavigationProp } from "navigation/type";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import * as authClient from "utils/authClient";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import config from "app/config";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";

enum EXPENSE_LOGIN_MESSAGE {
  LOGIN_FAILED = "login_failed",
  ACCOUNT_DEACTIVATED = "ACCOUNT_DEACTIVATED",
  INCORRECT_EMAIL_OR_PASSWORD = "incorrect_email_or_password",
}

type LoginProps = {
  errorFromOtp?: Error;
  onSignUpLinkClick: () => void;
};

const Login: FC<LoginProps> = ({ errorFromOtp }) => {
  const { t } = useTranslation("screens/Auth/Login");
  const navigation = useNavigation<LoginScreenNavigationProp>();
  const { objectLogin, fullname, isEnrolled, getObjectAuth, switchToDifferentAccount } = useLocalAuthLogin();
  const { isFaceIdAvailable, isIrisAvailable, isFingerprintAvailable, isMultipleSupport } =
    useSupportedAuthenticationTypes();
  const { login, refreshDataUser } = useAuth();
  const [visible, setVisible] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const passwordRef = useRef<TextInput>(null);
  const usernameRef = useRef<TextInput>(null);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const refForm = useRef<React.Ref<FormikProps<{}>>>(null);

  const yupSchema = Yup.object().shape({
    password: Yup.string().required(t("password_required")),
    username: Yup.string().email(t("email_valid")).required(t("required")),
  });

  const initialValues = useMemo(() => {
    return {
      username: DEV_INITIAL_USER_EMAIL ?? "",
      password: DEV_INITIAL_USER_PASSWORD ?? "",
    };
  }, []);

  const clearLoading = useCallback(() => {
    Toast.removeAll();
  }, []);
  const renderIconAuthentication = (size = 48) => {
    if (isMultipleSupport) {
      return <BiometricsIcon width={size - 8} height={size - 8} color={Colors.primary50} />;
    }
    return isFingerprintAvailable ? (
      <FingerPrint width={size} height={size} color={Colors.primary50} />
    ) : isFaceIdAvailable ? (
      <FaceIdIcon width={size} height={size} color={Colors.primary50} />
    ) : isIrisAvailable ? (
      <IrisIcon width={size} height={size} color={Colors.primary50} />
    ) : null;
  };
  const renderLabelAuthentication = () => {
    if (isMultipleSupport) {
      return t("login_by_biometrics");
    }
    return isFingerprintAvailable
      ? t("login_by_fingerprint")
      : isFaceIdAvailable
      ? t("login_by_faceid")
      : isIrisAvailable
      ? t("login_by_iris")
      : "";
  };
  const onSubmit = useCallback(
    async (values, actions) => {
      Keyboard.dismiss();
      Toast.loading({ content: "" });
      try {
        const challenge = await authClient.userRequestMFA(values.username);
        if (!challenge?.success) {
          analyticService.logEvent({ name: EVENT.AUTH.LOGIN_FAILED });
          throw new Error("unexpected_error");
        } else if (!challenge.challenge_id) {
          analyticService.logEvent({ name: EVENT.AUTH.LOGIN_SUCCESS });
          await login(values);
          requestAnimationFrame(() => {
            actions.setSubmitting(false);
          });
        } else {
          return navigation.navigate(SCREEN_NAME.LoginOTPScreen, {
            username: values.username,
            password: values.password,
            challengeId: challenge.challenge_id,
          });
        }
      } catch (error: any) {
        const { payload } = error;
        if (payload.errorCode === EXPENSE_LOGIN_MESSAGE.ACCOUNT_DEACTIVATED) {
          requestAnimationFrame(() => {
            actions.setSubmitting(false);
            navigation.navigate(SCREEN_NAME.AccountDeletedScreen);
          });
          return;
        }
        analyticService.logEvent({ name: EVENT.AUTH.LOGIN_FAILED });
        actions.setStatus({
          general: t(`${toLower(payload?.errorCode || "unexpected_error")}`),
        });

        requestAnimationFrame(() => {
          actions.setSubmitting(false);
          clearLoading();
        });
      }
    },
    [login, clearLoading, t]
  );

  useEffect(() => {
    if (refForm.current && errorFromOtp) {
      const form: any = refForm.current;
      form.setStatus({
        general: t(`${toLower(errorFromOtp?.payload?.errorCode || "unexpected_error")}`),
      });
    }
  }, [errorFromOtp, t]);

  useEffect(() => {
    navigation.addListener("blur", clearLoading);

    return () => {
      navigation.removeListener("blur", clearLoading);
    };
  }, []);

  // useEffect(() => {
  //   let timeout = null;
  //   if (objectLogin.password && objectLogin.username) {
  //     timeout = setTimeout(() => {
  //       refForm.current.submitForm();
  //     }, 250);
  //   }
  //
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [objectLogin]);

  const onPressForgetPassword = useCallback(() => {
    navigation.navigate(ScreenName.ForgotPasswordScreen);
  }, [navigation]);

  const onLoginWithLocalAuth = useCallback(async () => {
    if (isEnrolled) {
      await getObjectAuth();
    } else {
      setVisible(true);
    }
  }, [isEnrolled, getObjectAuth]);

  const handleChangeUserName =
    (handleChange: (e: string | ChangeEvent<any>) => void) => (e: string | ChangeEvent<any>) => {
      if (isString(e)) {
        handleChange(e.trim());
        return;
      }
      e.target.value = e.target.value.trim();
      handleChange(e);
    };
  const showLoginPopup = () => {
    const maxWidth = 600;
    const maxHeight = 300;
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;

    const width = Math.min(maxWidth, screenWidth);
    const height = Math.min(maxHeight, screenHeight);

    const left = screenWidth / 2 - width / 2;
    const top = screenHeight / 2 - height / 2;

    const popup = window.open(
      `${config.ACCOUNT_ENDPOINT}/user/auth/sso`,
      "expense-app",
      `popup,width=${width},height=${height},left=${left},top=${top}`
    );
    if (popup) {
      window.addEventListener("message", async (event) => {
        if (event.origin === config.ACCOUNT_ENDPOINT && event.data.token) {
          await AsyncStorage.setItem(Keys.token, event.data.token);
          await AsyncStorage.setItem(Keys.refreshToken, event.data.refreshToken);

          await refreshDataUser();
          popup.close();
        }
      });
    } else {
      ToastManager.error("Popup blocked. Please allow popups for this website.");
    }
  };
  const handleLoginWithSSO = () => {
    if (Platform.OS === "web") {
      showLoginPopup();
    } else {
      navigation.navigate(SCREEN_NAME.LoginWithSSOScreen);
    }
  };
  return (
    <>
      <StatusBar barStyle="dark-content" />
      {!fullname && (
        <View style={styles.loginTitleContainer}>
          <AppText style={Fonts.H600}>{t("login")}</AppText>
          {/*Platform.OS === "web" ? null : (
            <View style={styles.registerTitle}>
              <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale80}>
                {t("you_not_account")}
              </AppText>
              <TouchableOpacity
                style={styles.buttonRegister}
                onPress={() => {
                  Keyboard.dismiss();
                  onSignupLinkClick();
                }}
              >
                <AppText style={Fonts.H200} color={Colors.primary50}>
                  {t("sign_up_now")}
                </AppText>
              </TouchableOpacity>
            </View>
          )*/}
        </View>
      )}
      <Formik
        innerRef={refForm}
        enableReinitialize
        initialValues={objectLogin?.username ? objectLogin : initialValues}
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={yupSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            isValid,
            status,
            handleChange,
            handleSubmit,
            isSubmitting,
            errors,
            handleBlur,
            resetForm,
          } = props;

          return (
            <>
              <View style={styles.container}>
                {fullname ? (
                  <View style={styles.welcomeContainer}>
                    <View style={styles.welcomeCard}>
                      <AppText style={[Fonts.H500, styles.welcomeTitle]} numberOfLines={2}>
                        {t("welcome_title", { name: fullname })}!
                      </AppText>
                      <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
                        {t("nice_to_see_you_again")}
                      </AppText>
                    </View>
                    <Image source={BizziBotLogin} style={styles.bizziBotImage} />
                  </View>
                ) : (
                  <TextInput
                    ref={usernameRef}
                    style={styles.textInput}
                    label={t("Email")}
                    placeholder={t("Email")}
                    keyboardType="email-address"
                    textContentType="emailAddress"
                    autoCapitalize="none"
                    touched={touched.username}
                    onBlur={handleBlur("username")}
                    value={values.username}
                    autoCorrect={false}
                    error={errors.username}
                    onChangeText={handleChangeUserName(handleChange("username"))}
                    onSubmitEditing={() => passwordRef.current?.focus()}
                  />
                )}

                <TextInput
                  secureTextEntry={!showPassword}
                  style={styles.textInput}
                  label={t("password")}
                  placeholder={t("password")}
                  ref={passwordRef}
                  returnKeyType="go"
                  onBlur={handleBlur("password")}
                  touched={touched.password}
                  value={values.password}
                  error={errors.password}
                  onChangeText={handleChange("password")}
                  onSubmitEditing={handleSubmit}
                  right={
                    <TouchableOpacityCustom onPress={() => setShowPassword((prevState) => !prevState)}>
                      {!showPassword ? <IconCustom name="visibility-off" /> : <IconCustom name="visibility" />}
                    </TouchableOpacityCustom>
                  }
                />
                <View style={styles.forgetPasswordContainer}>
                  <TouchableOpacityCustom eventName={EVENT.AUTH.TAP_FORGOT_PASSWORD} onPress={onPressForgetPassword}>
                    <AppText style={Fonts.BodySmall} color={Colors.primary50}>
                      {t("forget_password_title")}
                    </AppText>
                  </TouchableOpacityCustom>
                </View>

                {!!status?.general && <Text style={styles.error}>{status?.general}</Text>}
                <WhiteSpace size="xl" />
                <View style={styles.button}>
                  <Button disabled={!isValid || isSubmitting} onPress={handleSubmit}>
                    {t("sign_in")}
                  </Button>
                  <AppText style={styles.orTitle} color={Colors.grayscale70}>
                    {t("or")}
                  </AppText>
                  <Button onPress={handleLoginWithSSO} type="secondary">
                    {t("login_with_sso")}
                  </Button>
                  {!!fullname && (
                    <Button
                      style={styles.buttonNotMe}
                      type="secondary"
                      labelStyle={styles.labelNotMe}
                      onPress={async () => {
                        await switchToDifferentAccount();
                        resetForm();
                      }}
                    >
                      {t("button_confirm_not_me")}
                    </Button>
                  )}
                  {Platform.OS === "web" ? null : (
                    <View style={styles.wrapperLocalAuth}>
                      <TouchableOpacityCustom
                        eventName={EVENT.AUTH.TAP_LOGIN_BY_BIOMETRICS}
                        style={styles.faceIdButton}
                        onPress={onLoginWithLocalAuth}
                      >
                        {renderIconAuthentication()}
                      </TouchableOpacityCustom>
                      <TouchableOpacityCustom
                        eventName={EVENT.AUTH.TAP_LOGIN_BY_BIOMETRICS}
                        onPress={onLoginWithLocalAuth}
                      >
                        <AppText style={Fonts.H200}>{renderLabelAuthentication()}</AppText>
                      </TouchableOpacityCustom>
                    </View>
                  )}
                </View>
              </View>
            </>
          );
        }}
      </Formik>
      {Platform.OS === "web" ? null : (
        <Portal
          theme={{
            colors: {
              backdrop: "rgba(9, 30, 66, 0.3)",
            },
          }}
        >
          <Dialog
            visible={visible}
            onDismiss={() => {
              setVisible(false);
            }}
          >
            <Dialog.Content>
              <View style={styles.wrapperNotifyUser}>
                {renderIconAuthentication(96)}
                <Paragraph style={styles.textWarningsLocalAuth}>{t("alert_description")}</Paragraph>
              </View>
            </Dialog.Content>
            <Dialog.Actions>
              <Button
                mode="contained"
                onPress={() => {
                  setVisible(false);
                }}
                labelStyle={{ color: "#001629" }}
                style={styles.buttonStyle}
              >
                {t("alert_confirm_button")}
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },

  loginTitleContainer: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  /*registerTitle: { flexDirection: "row", marginTop: 4 },
  buttonRegister: { marginLeft: 8 },*/
  textInput: { marginTop: 16 },
  faceIdButton: { marginRight: 15 },
  button: {
    // paddingHorizontal: SPACING_DEFAULT,
  },
  bizziBotImage: { width: 72, height: 72 },
  error: {
    fontSize: 14,
    color: Colors.alert50,
    textAlign: "center",
    marginTop: 10,
  },
  wrapperLocalAuth: {
    marginTop: 30,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  welcomeContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  welcomeCard: {
    flex: 1,
    marginRight: 12,
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderRadius: 18,
    shadowColor: Colors.grayscale60,
    backgroundColor: Colors.white,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 1.5,
    elevation: 3,
  },
  labelNotMe: { color: Colors.grayscale100 },
  welcomeTitle: { marginBottom: 4 },
  buttonNotMe: { marginTop: 16 },
  forgetPasswordContainer: { alignItems: "flex-end", marginTop: 15 },
  wrapperNotifyUser: {
    justifyContent: "center",
    alignItems: "center",
  },
  textWarningsLocalAuth: {
    marginTop: 24,
    fontSize: 14,
    lineHeight: 20,
    textAlign: "center",
    color: "#5E6C84",
  },
  buttonStyle: {
    paddingHorizontal: 20,
    // paddingVertical: 2,
    shadowColor: "white",
    elevation: 0,
    backgroundColor: "#F3F4F6",
    width: "100%",
  },
  orTitle: { ...Fonts.BodyRegular, textAlign: "center", marginVertical: 16 },
});

export default Login;
