import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  MobileExpAssignableExpenseReportExpensesQuery,
  MobileExpAssignableExpenseReportExpensesQueryVariables,
} from "types";

const QUERY_UNCLAIMED_EXPENSES = gql`
  query MobileExpAssignableExpenseReportExpenses(
    $where: ExpAssignableExpenseReportExpensesWhereInput!
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
  ) {
    expAssignableExpenseReportExpenses(where: $where, limit: $limit, offset: $offset) {
      expenses {
        expenseId
        docSequence
        expenseRequestId
        employeeId
        note
        status
        totalAmountWithVat
        totalAmountWithoutVat
        foreignTotalAmountWithVat
        exchangeRate
        expenseDate
        companyTeamId
        expenseCardTransactions {
          cardTransaction {
            amount
          }
          cardTransactionId
        }
        expenseCategory {
          expenseCategoryId
          title
          titleEn
        }
        expenseRequest {
          cashAdvances {
            amount
          }
          expenseRequestId
          docSequence
          title
          totalAmount
        }
      }
      total
    }
  }
`;

const useExpenseUnClaimForReportQuery = (variables: MobileExpAssignableExpenseReportExpensesQueryVariables) => {
  return useLazyQuery<
    MobileExpAssignableExpenseReportExpensesQuery,
    MobileExpAssignableExpenseReportExpensesQueryVariables
  >(QUERY_UNCLAIMED_EXPENSES, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpenseUnClaimForReportQuery;
