import { MobileExpenseReportDetailsQuery } from "types/generated";

type ExpenseRelevantAmount = {
  /**Sum of expenses' amounts */
  totalAmountWithVat: number;
  totalCashAdvanceAmount: number;
  totalCardTransactionAmount: number;
  /**Sum of cash advance and card transaction from every expense */
  paidAmount: number;
  /**Difference between totalAmountWithVat and paidAmount */
  payableAmount: number;
};

export const ExpenseHelper = {
  getExpensesRelevantAmounts: (
    expenses: MobileExpenseReportDetailsQuery["expExpenseReportDetails"]["expenses"],
    exchangeRate?: number
  ): ExpenseRelevantAmount => {
    if (!expenses) {
      return {
        totalAmountWithVat: 0,
        totalCashAdvanceAmount: 0,
        totalCardTransactionAmount: 0,
        paidAmount: 0,
        payableAmount: 0,
      };
    }
    let totalAmountWithVat = 0;
    let totalCashAdvanceAmount = 0;
    let totalCardTransactionAmount = 0;

    expenses.forEach((expense) => {
      // Calculate total cash advance amount
      totalCashAdvanceAmount += expense.expenseRequest?.cashAdvances?.[0]?.amount || 0;
      if (expense.expenseCardTransactions) {
        totalCardTransactionAmount += Math.abs(expense.expenseCardTransactions[0]?.cardTransaction?.amount || 0);
      }
      totalAmountWithVat += expense.exchangeRate
        ? expense.foreignTotalAmountWithVat * (exchangeRate ?? expense.exchangeRate)
        : expense.totalAmountWithVat;
    });

    const paidAmount = totalCashAdvanceAmount + totalCardTransactionAmount;
    const payableAmount = Math.abs(totalAmountWithVat - paidAmount);

    return {
      totalAmountWithVat,
      totalCashAdvanceAmount,
      totalCardTransactionAmount,
      paidAmount,
      payableAmount,
    };
  },
};
