import { CONSTANTS } from "constants/constants";
import { StyleSheet } from "react-native";
import { Colors } from "theme";
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale05 },
  marginVertical: {
    marginTop: 16,
    marginBottom: 10,
  },
  inputContainer: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 20,
    paddingBottom: 20,
  },
  expenseListContainer: {
    marginTop: 8,
    paddingTop: 20,
    backgroundColor: Colors.white,
  },
  buttonContainer: { flexDirection: "row", justifyContent: "space-between" },
  buttonSaveDaft: { flex: 1, marginRight: 4 },
  buttonSubmit: { flex: 1, marginLeft: 4 },
  expenseListTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginBottom: 22,
  },
  dropdownSelect: {
    marginTop: 6,
  },
  warningIcon: { marginRight: 15, marginTop: 2 },
  rejectReason: {
    paddingVertical: 10,
    paddingHorizontal: 14,
    backgroundColor: Colors.alert05,
    marginBottom: 20,
    borderRadius: 8,
  },
  totalAmountContainer: {
    marginBottom: 8,
  },
  paidAmountContainer: { marginBottom: 8, marginTop: 2 },
  flex: {
    flex: 1,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  paymentItem: {
    flexDirection: "row",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 17,
  },
  optionIcon: {
    marginRight: 16,
  },
  centerContent: {
    alignItems: "center",
  },
  reportHasAdvance: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    alignItems: "center",
    marginBottom: 20,
    gap: 8,
  },
  reportHasAdvanceInfo: { flexDirection: "row", gap: 4, alignItems: "center", flex: 1 },
  descriptionAdvance: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    marginHorizontal: 20,
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: Colors.warning0,
    borderRadius: 8,
    marginBottom: 20,
  },
  descriptionItem: { flexDirection: "row", gap: 8 },
  dotIcon: { width: 5, height: 5, borderRadius: 3, backgroundColor: Colors.grayscale100, marginTop: 6 },
});

export default styles;
