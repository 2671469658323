import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import React, { FC, Fragment, memo, useMemo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { CircleCheckedBox, DeleteIcon, ExpenseItemIcon } from "assets/images/svg/icons";
import { AllocatedStatus, AppText, CurrencyText, IconCustom, Line } from "components";
import StatusExpense from "components/ExpenseItem/StatusExpense";
import { CONSTANTS } from "constants/constants";
import { isEmpty } from "lodash";
import SCREEN_NAME from "navigation/ScreenName";
import { ExpenseDetailScreenNavigationProp } from "navigation/type";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import { getTitleFromCategory } from "utils";
import { useReportManage } from "screens/Report/context/ReportManageProvider";

interface ExpenseListSectionProps {
  expenses: {
    expenseDate?: string;
    title?: string;
    titleColor?: string;
    expenseRequestId?: string;
    groupedExpenses: any[];
  }[];
  requestValidateReportAmount?: any[];
  handleRemoveExpense?: (item) => void;
  groupBy?: string;
  handleRemoveRequest?: (expenseRequestId: string) => void;
  onRefreshData?: () => void;
  enablePartialApproval?: boolean;
  foreignExchangeRate?: boolean;
}

const ExpenseItem = ({
  expense,
  handleRemoveExpense,
  showDivider = true,
  styles,
  onRefreshData,
  isCanPartialApproval,
  foreignExchangeRate = undefined,
}) => {
  const { t } = useTranslation("app/screens/Report/components/CreateOrEditForm");
  const navigation = useNavigation<ExpenseDetailScreenNavigationProp>();
  const { setApprovedExpenseIds, approvedExpenseIds } = useReportManage();

  const isSelected = useMemo(() => {
    return approvedExpenseIds?.includes(expense.expenseId);
  }, [approvedExpenseIds]);

  const handleNavigateToExpenseDetail = () => {
    navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, {
      expenseId: expense.expenseId,
      onRefreshData,
    });
  };

  const totalAmountWithVat = expense.exchangeRate
    ? expense.foreignTotalAmountWithVat * (foreignExchangeRate ?? expense.exchangeRate)
    : expense.totalAmountWithVat;

  const handleSelectItemToApprove = () => {
    if (!approvedExpenseIds?.includes(expense.expenseId)) {
      setApprovedExpenseIds(approvedExpenseIds.concat([expense.expenseId]));
    } else {
      setApprovedExpenseIds(approvedExpenseIds.filter((item) => item !== expense.expenseId));
    }
  };

  const renderExpenseItemBody = () => {
    return (
      <>
        {isSelected ? <CircleCheckedBox width={48} height={48} /> : <ExpenseItemIcon />}
        <View style={styles.contentContainer}>
          {Boolean(expense?.expenseCardTransactions?.[0]) && (
            <AppText style={[Fonts.BodySmall, { marginBottom: 2 }]} color={Colors.grayscale60}>
              {t("expense_from_transaction")}
            </AppText>
          )}
          <View style={styles.rowItem}>
            <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale100} numberOfLines={1}>
              {expense?.note?.trim() || t("need_explanation")}
            </AppText>
            <CurrencyText
              style={Fonts.BodyMedium}
              foreignAmountStyle={Fonts.BodyRegular}
              foreignAmount={expense?.foreignTotalAmountWithVat}
            >
              {totalAmountWithVat}
            </CurrencyText>
          </View>
          <View style={[styles.rowItem, { marginTop: 3 }]}>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale80}>
              {expense?.docSequence}
            </AppText>
            <View style={styles.pricingContainer}>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                {dayjs(expense?.expenseDate)?.format(CONSTANTS.FORMAT_DAY)}
              </AppText>
              {handleRemoveExpense && (
                <TouchableOpacity style={styles.paddingLeft4} onPress={handleRemoveExpense(expense)}>
                  <DeleteIcon />
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={styles.expenseItemFooter}>
            <View style={styles.statusContainer}>
              <StatusExpense value={expense?.status} />
              <AllocatedStatus value={expense?.allocatedStatus} />
            </View>
            {isCanPartialApproval && (
              <TouchableOpacity onPress={handleNavigateToExpenseDetail} style={styles.viewButton}>
                <AppText style={Fonts.BodySmall} color={Colors.primary50}>
                  {t("view")}
                </AppText>
                <IconCustom name="arrow-right-alt" />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </>
    );
  };

  if (isCanPartialApproval) {
    return (
      <TouchableOpacity onPress={handleSelectItemToApprove}>
        <View style={styles.expenseItem}>{renderExpenseItemBody()}</View>
        {showDivider && <Line containerStyle={styles.expenseDivider} />}
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity onPress={handleNavigateToExpenseDetail}>
      <View style={styles.expenseItem}>{renderExpenseItemBody()}</View>
      {showDivider && <Line containerStyle={styles.expenseDivider} />}
    </TouchableOpacity>
  );
};

const ExpenseGroupedByRequestAndBudgetListSection: FC<ExpenseListSectionProps> = ({
  expenses,
  requestValidateReportAmount,
  handleRemoveExpense,
  groupBy,
  handleRemoveRequest,
  onRefreshData,
  enablePartialApproval,
  foreignExchangeRate,
}) => {
  const navigation = useNavigation<any>();

  const handlePressTitle = (expenseRequestId) => {
    navigation.navigate(SCREEN_NAME.DetailRequestScreen, { expenseRequestId });
  };
  return (
    <>
      {expenses?.map(({ title, titleColor, groupedExpenses, expenseRequestId }, index) => {
        return (
          <Fragment key={index}>
            {index !== 0 && <View style={styles.line} />}
            <View style={styles.header}>
              <View style={styles.titleWrapper}>
                <TouchableOpacity disabled={!expenseRequestId} onPress={() => handlePressTitle(expenseRequestId)}>
                  <AppText
                    style={[Fonts.SentenceSubtitleLarge, styles.flex]}
                    color={titleColor ?? Colors.grayscale80}
                    numberOfLines={1}
                  >
                    {title}
                  </AppText>
                </TouchableOpacity>
              </View>
              {Boolean(handleRemoveRequest) && (
                <TouchableOpacity style={styles.paddingLeft4} onPress={() => handleRemoveRequest(expenseRequestId)}>
                  <DeleteIcon />
                </TouchableOpacity>
              )}
            </View>

            <View>
              {groupedExpenses.map((categoryExpense, cateIndex) => {
                console.log(foreignExchangeRate, "ForeignExchangeRate");
                const expenses = categoryExpense.expenses ?? [];
                const category = expenses?.[0]?.expenseCategory;
                const categoryTitle = getTitleFromCategory({ title: category?.title, titleEn: category?.titleEn });

                const totalAmount = expenses.reduce((currentValue, nextValue) => {
                  const totalAmountWithVat = nextValue.exchangeRate
                    ? nextValue.foreignTotalAmountWithVat * (foreignExchangeRate ?? nextValue.exchangeRate)
                    : nextValue.totalAmountWithVat;

                  return currentValue + totalAmountWithVat;
                }, 0);

                const foreignTotalAmountWithVat = expenses.reduce((currentValue, nextValue) => {
                  return currentValue + nextValue.foreignTotalAmountWithVat;
                }, 0);

                let errorRequestValidateAmount;
                if (groupBy === "request") {
                  errorRequestValidateAmount = requestValidateReportAmount?.find(
                    (validatedCategory) =>
                      expenses[0].expenseRequestId === validatedCategory.expenseRequestId &&
                      validatedCategory.expenseCategoryId === expenses[0]?.expenseCategory?.expenseCategoryId
                  );
                }
                if (groupBy === "budget") {
                  errorRequestValidateAmount = requestValidateReportAmount?.find(
                    (validatedCategory) =>
                      validatedCategory.expenseCategoryId === expenses[0]?.expenseCategory?.expenseCategoryId
                  );
                }
                const isValidBudget = isEmpty(errorRequestValidateAmount) || errorRequestValidateAmount?.isValid;

                return (
                  <Fragment key={cateIndex}>
                    <View>
                      <View style={styles.padding20}>
                        <View style={styles.categoryTitle}>
                          <View style={styles.flex2}>
                            <AppText style={Fonts.NumericN300} color={Colors.grayscale100} numberOfLines={1}>
                              {categoryTitle}
                            </AppText>
                          </View>
                          <View style={{ paddingLeft: 4 }}>
                            <CurrencyText
                              style={Fonts.SentenceSubtitleLarge}
                              color={isValidBudget ? Colors.grayscale100 : Colors.alert50}
                              foreignAmount={foreignTotalAmountWithVat}
                              foreignAmountStyle={Fonts.BodyLarge}
                            >
                              {totalAmount}
                            </CurrencyText>
                          </View>
                        </View>
                        {isValidBudget ? undefined : (
                          <View style={styles.alignExpenseItem}>
                            <AppText style={Fonts.Caption} color={Colors.alert50}>
                              {errorRequestValidateAmount?.message}
                              {errorRequestValidateAmount?.overBudgetAmount > 0 && (
                                <>
                                  {" "}
                                  <CurrencyText style={Fonts.Caption} color={Colors.alert50}>
                                    {errorRequestValidateAmount?.overBudgetAmount}
                                  </CurrencyText>
                                </>
                              )}
                            </AppText>
                          </View>
                        )}
                      </View>
                      <Line containerStyle={{ backgroundColor: Colors.grayscale05 }} />
                      <View style={styles.flex}>
                        {expenses.map((expense, expenseIndex) => (
                          <ExpenseItem
                            key={expense.expenseId}
                            expense={expense}
                            handleRemoveExpense={handleRemoveExpense}
                            showDivider={expenseIndex !== expenses?.length - 1}
                            styles={styles}
                            onRefreshData={onRefreshData}
                            isCanPartialApproval={enablePartialApproval}
                            foreignExchangeRate={foreignExchangeRate}
                          />
                        ))}
                      </View>
                      {cateIndex !== groupedExpenses?.length - 1 && (
                        <Line containerStyle={{ backgroundColor: Colors.grayscale05 }} />
                      )}
                    </View>
                  </Fragment>
                );
              })}
            </View>
          </Fragment>
        );
      })}
    </>
  );
};

export default memo(ExpenseGroupedByRequestAndBudgetListSection);

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    gap: 10,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  contentContainer: {
    flex: 1,
  },
  flex: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  pricingContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
  },
  padding20: {
    padding: 20,
  },
  paddingLeft4: {
    paddingLeft: 4,
  },
  expenseDivider: {
    backgroundColor: Colors.grayscale05,
    marginLeft: 25,
  },
  titleWrapper: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    backgroundColor: Colors.white,
  },
  categoryTitle: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  alignExpenseItem: {
    alignItems: "flex-end",
  },
  expenseItem: {
    padding: 20,
    alignItems: "center",
    flexDirection: "row",
    gap: 16,
  },
  rowItem: { flexDirection: "row", gap: 8 },
  line: { height: 8, backgroundColor: Colors.grayscale0 },
  expenseItemFooter: {
    marginTop: 4,
    flexDirection: "row",
    gap: 4,
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusContainer: { flexDirection: "row", gap: 4 },
  viewButton: {
    flexDirection: "row",
    gap: 4,
    alignSelf: "flex-end",
    padding: 5,
  },
});
