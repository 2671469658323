import { ScrollView, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import React, { FC, useMemo } from "react";
import { MobileExpenseReportDetailsQuery } from "types";
import { Colors } from "theme";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { LoadingView } from "components";
import ReportStatus from "constants/ReportStatus";
import ExpenseListSection from "../ExpenseListSection/ExpenseListSection";
import TotalAmountInfo from "screens/Report/components/CreateOrEditForm/TotalAmountInfo";
import { ExpenseHelper } from "utils/expense";

interface ExpenseTabProps {
  expenseReport?: MobileExpenseReportDetailsQuery["expExpenseReportDetails"];
  isApproverView?: boolean;
  loading?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  enablePartialApproval?: boolean;
}
const ExpenseTab: FC<ExpenseTabProps> = ({ contentContainerStyle, loading, expenseReport, enablePartialApproval }) => {
  const [FEATURE_EXP_REIMBURSABLE_EXPENSE] = useIsFeaturesEnabled([FEATURE_FLAGS.FEATURE_EXP_REIMBURSABLE_EXPENSE]);

  const { totalAmountWithVat, totalCashAdvanceAmount, totalCardTransactionAmount } =
    ExpenseHelper.getExpensesRelevantAmounts(expenseReport?.expenses);

  const exchangeRate = expenseReport?.customFields?.exchange_rate;

  return (
    <View style={styles.container}>
      {loading ? (
        <LoadingView style={styles.loadingView} />
      ) : (
        <ScrollView contentContainerStyle={contentContainerStyle} showsVerticalScrollIndicator={false}>
          <ExpenseListSection
            isValidateBudget={expenseReport?.status === ReportStatus.SUBMITTED}
            data={expenseReport}
            enablePartialApproval={enablePartialApproval}
          />
          {FEATURE_EXP_REIMBURSABLE_EXPENSE && (
            <TotalAmountInfo
              totalAmount={totalAmountWithVat}
              cashAdvanceAmount={totalCashAdvanceAmount}
              cardTransactionAmount={totalCardTransactionAmount}
              foreignExchangeRate={exchangeRate}
            />
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default ExpenseTab;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale05 },
  loadingView: { backgroundColor: Colors.white },
});
