import "expo-dev-client";
import "intl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/vi-VN";
import "lang";
import "react-native-gesture-handler";
import "react-native-get-random-values";
import "./styles.css";

import "./reactotron";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import React from "react";

import { Provider } from "@ant-design/react-native";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { ApolloProvider } from "@apollo/client";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import * as Analytics from "./app/utils/analytics";
import numbro from "numbro";
import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { Provider as PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Toast from "react-native-toast-message";

import createApolloClient from "./app/apollo/clientConfig";
import {
  CopilotProviderCustom,
  FullPageSpinner,
  ToastManager,
  ToastMessage,
  useWindowDimensions,
} from "./app/components";
import { AuthProvider } from "./app/contexts/AuthContext";
import FeatureFlagContext from "./app/contexts/FeatureFlagContext";
import FeatureManagementContext, { useFeatureFlags } from "./app/contexts/FeatureManagementContext";
import LocalizationProvider from "./app/contexts/LocalizationContext";
import NetInfoContext from "./app/contexts/NetInfoContext";
import NotificationContext from "./app/contexts/NotificationContext";
import useCachedResources from "./app/hooks/useCachedResources";
import useServicePlanInfo from "./app/hooks/useServicePlanInfor";
import i18n from "./app/lang";
import AppStack from "./app/navigation/AppStack";
import { THEME } from "./app/navigation/Setting";
import { AppState, LogBox, Platform, StatusBar, StyleSheet, UIManager } from "react-native";
import { Colors } from "./app/theme";
import { sentryService } from "./app/services/SentryService";
import { MasterDataProvider } from "./app/contexts/MasterDataContext";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import dayjs from "dayjs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localizedFormat from "dayjs/plugin/localizedFormat";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import customParseFormat from "dayjs/plugin/customParseFormat";
import LanguageStatus from "./app/constants/LanguageStatus";
import * as SplashScreen from "expo-splash-screen";
import { widgetService } from "./app/services/WidgetService";
import FlightBookingProvider from "./app/screens/FlightBooking/context/FlightBookingProvider";
import Animated from "react-native-reanimated";
import OTAUpdateModal from "./app/components/OTAUpdateModal";
import otaService, { UpdateStatusCode } from "./app/services/OtaService";
import * as Sentry from "@sentry/react-native";
import { InAppFeedbackProvider } from "./app/contexts/InAppFeedbackContext";
import { WalkthroughGuideProvider } from "./app/contexts/WalkthroughGuideContext/WalkthroughGuideContext";
import { MenuProvider } from "react-native-popup-menu";

Animated.addWhitelistedNativeProps({ text: true });
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
SplashScreen.preventAutoHideAsync();
numbro.registerLanguage({
  languageTag: "vi-VN",

  delimiters: {
    thousands: ",",
    decimal: ".",
  },
  abbreviations: {
    thousand: "k",
    million: "tr",
    billion: "tỷ",
    trillion: "nt",
  },
  ordinal: (number) => {
    return number === 1 ? "đ" : "đ";
  },
  currency: {
    symbol: " ",
    position: "postfix",
    code: "VND",
  },
  currencyFormat: {
    thousandSeparated: true,
    totalLength: 3,
    spaceSeparated: true,
    average: true,
  },
  formats: {
    fourDigits: {
      totalLength: 4,
      spaceSeparated: true,
      average: true,
    },
    fullWithTwoDecimals: {
      output: "currency",
      mantissa: 2,
      spaceSeparated: true,
      thousandSeparated: true,
    },
    fullWithTwoDecimalsNoCurrency: {
      mantissa: 2,
      thousandSeparated: true,
    },
    fullWithNoDecimals: {
      output: "currency",
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 0,
    },
  },
});

numbro.setLanguage("vi-VN");

if (__DEV__ && Platform.OS !== "web") {
  // import("./reactotron/index.native").then(() => console.log("Reactotron Configured"));
}

LogBox.ignoreLogs(["useBottomSheetDynamicSnapPoints"]);

if (Platform.OS !== "web") {
  Analytics.setDebugModeEnabled(__DEV__);
  Analytics.setUnavailabilityLogging(true);
  sentryService.init();
}
if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}
const client = createApolloClient();

const ApolloContainer = memo(() => {
  const { width } = useWindowDimensions();
  const { i18n: i18nCurrent } = useTranslation();
  const [visibleRestartAppModal, setVisibleRestartAppModal] = useState(false);
  const isLoadingComplete = useCachedResources();

  const { servicePlan, waitingUpgrade } = useServicePlanInfo();
  const { features, loading } = useFeatureFlags();
  const featuresInfo = {
    servicePlan: servicePlan || "standard",
    features: !loading && features,
    waitingUpgrade,
  };

  const handleCheckForAppUpdates = async () => {
    const result = await otaService.updateApp();
    if (result.status === UpdateStatusCode.UpdatedSuccess && result.shouldNotifyChange) {
      setVisibleRestartAppModal(true);
    }
  };
  useEffect(() => {
    if (Platform.OS !== "web" && !__DEV__) {
      handleCheckForAppUpdates();
    }
  }, []);

  useEffect(() => {
    if (i18nCurrent.language === LanguageStatus.VN) {
      dayjs.locale("vi");
    } else {
      dayjs.locale("en");
    }
  }, [i18nCurrent?.language]);

  useLayoutEffect(() => {
    if (Platform.OS === "web") {
      const body = document.getElementsByTagName("body")[0];
      body.style.background = Colors.grayscale0;
      const root: any = document.getElementById("root");
      root.style.maxWidth = `${width}px`;
      root.style.marginRight = "auto";
      root.style.marginLeft = "auto";
    }
  }, []);

  if (!isLoadingComplete) {
    return <FullPageSpinner />;
  }

  return (
    <GestureHandlerRootView style={styles.container}>
      <NotificationContext>
        <FeatureFlagContext>
          <FeatureManagementContext.Provider value={featuresInfo}>
            <MasterDataProvider>
              <CopilotProviderCustom>
                <BottomSheetModalProvider>
                  <FlightBookingProvider>
                    <InAppFeedbackProvider>
                      <WalkthroughGuideProvider>
                        <AppStack />
                      </WalkthroughGuideProvider>
                    </InAppFeedbackProvider>
                  </FlightBookingProvider>
                </BottomSheetModalProvider>
              </CopilotProviderCustom>
            </MasterDataProvider>
            <Toast />
            <ToastMessage ref={(ref) => ToastManager.setRefToastMessage(ref)} />
          </FeatureManagementContext.Provider>
        </FeatureFlagContext>
      </NotificationContext>
      <OTAUpdateModal visible={visibleRestartAppModal} setVisible={setVisibleRestartAppModal} />
    </GestureHandlerRootView>
  );
});

const App = () => {
  const appState = useRef(AppState.currentState);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", async (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === "active") {
        widgetService.reloadContent();
      }

      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <StatusBar barStyle="dark-content" />
      <ApolloProvider client={client}>
        <SafeAreaProvider>
          <MenuProvider>
            <Provider>
              <NetInfoContext>
                <AuthProvider>
                  <LocalizationProvider>
                    <PaperProvider theme={THEME}>
                      {Platform.OS === "web" ? (
                        <GTMProvider state={{ id: "GTM-NVJ68MQ" }}>
                          <ApolloContainer />
                        </GTMProvider>
                      ) : (
                        <ApolloContainer />
                      )}
                    </PaperProvider>
                  </LocalizationProvider>
                </AuthProvider>
              </NetInfoContext>
            </Provider>
          </MenuProvider>
        </SafeAreaProvider>
      </ApolloProvider>
    </I18nextProvider>
  );
};

export default !__DEV__ && Platform.OS !== "web" ? Sentry.wrap(App) : App;
const styles = StyleSheet.create({
  container: { flex: 1 },
});
