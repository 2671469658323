import { useCallback, useEffect, useState } from "react";
import useEnrolledLocalAuth from "hooks/useEnrolledLocalAuth";
import * as SecureStore from "expo-secure-store";
import { Keys } from "constants/Keys";

const useLocalAuthLogin = () => {
  const { isEnrolled, isValidLocalAuth, removeLocalAuth } = useEnrolledLocalAuth();
  const [objectLogin, setObjectLogin] = useState({ username: "", password: "" });
  const [fullname, setFullname] = useState("");

  const getObjectAuth = useCallback(async () => {
    const isValid = await isValidLocalAuth();

    if (isValid) {
      const username = await SecureStore.getItemAsync(Keys.USERNAME);
      const password = await SecureStore.getItemAsync(Keys.USER_PASSWORD);

      if (username && password) {
        setObjectLogin({
          username,
          password,
        });
      }
    }
  }, [isValidLocalAuth]);

  const getFullName = useCallback(async () => {
    if (!isEnrolled) {
      return;
    }
    const fullName = await SecureStore.getItemAsync(Keys.USER_FULLNAME);
    const username = await SecureStore.getItemAsync(Keys.USERNAME);
    if (fullName && username) {
      setFullname(fullName);
      setObjectLogin({
        username,
        password: "",
      });
    }
  }, [isEnrolled]);

  useEffect(() => {
    if (isEnrolled) {
      getObjectAuth();
    }
  }, [isEnrolled, getObjectAuth]);

  useEffect(() => {
    getFullName();
  }, [isEnrolled]);

  const switchToDifferentAccount = async () => {
    await removeLocalAuth();
    setFullname("");
    setObjectLogin({ username: "", password: "" });
  };

  return {
    objectLogin,
    isValidLocalAuth,
    isEnrolled,
    getObjectAuth,
    fullname,
    setFullname,
    switchToDifferentAccount,
  };
};

export default useLocalAuthLogin;
