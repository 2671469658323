import { BizziBotSticker01, BizziBotSticker02, BizziBotSticker03 } from "assets/images";
import {
  ApproverChooserModal,
  AppText,
  BottomSheetModalCustomMethods,
  Button,
  CompanyTeamSelect,
  CopilotStepCustom,
  CurrencyText,
  DatePickerCustom,
  FloatFooter,
  KeyboardAwareScrollViewCustom,
  Line,
  PickerCustom,
  TabViewInsideScrollView,
  TextInputCustom,
} from "components";
import UploadFiles from "components/InputCustom/UploadFiles/UploadFiles";
import {
  ACCOUNT_TYPES,
  ALLOWANCE_TYPE,
  APPROVAL_TYPE,
  BOOKING_TYPE,
  CONSTANTS,
  PAYMENT_METHOD,
  REQUEST_TYPE,
} from "constants/constants";
import { DatePickerMode } from "constants/DateSelectConstant";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import LanguageStatus from "constants/LanguageStatus";
import { EVENT } from "constants/Tracking";
import { useAuth } from "contexts/AuthContext";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { useMasterData } from "contexts/MasterDataContext";
import { useWalkthroughGuide } from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";
import dayjs from "dayjs";
import i18n from "i18next";
import isEmpty from "lodash/isEmpty";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useCopilot } from "react-native-copilot";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import HotelSection from "screens/ExpenseRequest/components/ExpenseRequestForm/HotelSection";
import PerDiemInfoSection from "screens/ExpenseRequest/components/ExpenseRequestForm/PerDiemInfoSection";
import { getRequestIconByType } from "screens/ExpenseRequest/helper";
import useExpEmployeeExpensePolicyTravel from "screens/ExpenseRequest/hooks/useExpEmployeeExpensePolicyTravel";
import { DateModalInfoData, ExpenseRequestFormAction, ExpenseRequestFormValues } from "screens/ExpenseRequest/types";
import { Colors, Fonts } from "theme";
import { countNumberOfDays } from "utils";
import AdvancePaymentSection from "./AdvancePaymentSection";
import AirlineTicketSection from "./AirlineTicketSection/AirlineTicketSection";
import AllowancesSection from "./AllowancesSection/AllowancesSection";
import BusTicketSection from "./BusTicketSection";
import CurrencySection from "./CurrencySection";
import PassengerListSection from "./PassengerListSection/PassengerListSection";
import TravelSelector from "./TravelSelector";

interface ExpenseRequestFormProps {
  onSubmit?: (type: ExpenseRequestFormAction, values: ExpenseRequestFormValues) => void;
  initialValues?: ExpenseRequestFormValues; // if exist --> flow update
  setIsDirty?: (bool: boolean) => void;
  disabledButton?: boolean;
  type?: REQUEST_TYPE;
  shouldShowWalkthrough?: boolean | string;
}
const ExpenseRequestForm: FC<ExpenseRequestFormProps> = ({
  onSubmit,
  initialValues,
  setIsDirty,
  disabledButton,
  type,
  shouldShowWalkthrough,
}) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const approverChooserModalRef = useRef<BottomSheetModalCustomMethods>(null);

  const [footerHeight, setFooterHeight] = useState(0);
  const airLineTicketRef = useRef<any>();
  const busTicketRef = useRef<any>();
  const hotelBookingRef = useRef<any>();
  const {
    requestTypes,
    approvalTypeRequest,
    setting: { allowPastTimeOnExpenseRequestCreation, isSupportCurrency, request: requestSetting },
  } = useMasterData();
  const {
    user: { employee_id },
    company: { company_id },
  } = useAuth();
  const [FEATURE_TEAM_MGMT, FEATURE_BUDGET_MGMT, FEATURE_EXP_CASH_ADVANCE, FEATURE_EXP_EXPENSE_REQUEST_NOTE] =
    useIsFeaturesEnabled([
      FEATURE_FLAGS.FEATURE_TEAM_MGMT,
      FEATURE_FLAGS.FEATURE_BUDGET_MGMT,
      FEATURE_FLAGS.FEATURE_EXP_CASH_ADVANCE,
      FEATURE_FLAGS.FEATURE_EXP_EXPENSE_REQUEST_NOTE,
    ]);
  const [onFetchExpensePolicyTravel, { data: expensePolicyTravel }] = useExpEmployeeExpensePolicyTravel({
    employeeId: employee_id,
  });
  const [dateModalInfo, setDateModalInfo] = useState<DateModalInfoData>({
    field: "",
    visible: false,
    date: undefined,
    minimumDate: undefined,
    maximumDate: undefined,
  });
  const expenseRequestTypes = requestTypes.map((expenseRequestType) => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    label: t(expenseRequestType as string),
    value: expenseRequestType as REQUEST_TYPE,
  }));

  /*-- start walkthrough flow --*/
  const { start, steps } = useCopilot();
  const { currentChildrenActionCode } = useWalkthroughGuide();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) =>
      steps[key].walkthroughName === CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME &&
      steps[key].order === CONSTANTS.WALK_THROUGH.CREATE_REQUEST.Step2
  )?.[0];

  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          suppliedScrollView: scrollViewRef.current,
          step: step,
          walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME,
        } as any);
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [stepNameToStartWalkthrough]);
  /*-- end --*/

  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };
  const isUpdate = Boolean(initialValues);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<ExpenseRequestFormValues>({
    shouldFocusError: false,
    defaultValues: {
      travel: null,
      travelDate: { fromDate: new Date(), toDate: new Date() },
      attachments: [],
      allowances: [],
      approverId: "",
      budgetDate: dayjs().toISOString(),
      hasCashAdvance: false,
      cashAdvance: {
        paymentMethod: PAYMENT_METHOD.BANK_TRANSFER,
      },
      paymentInfo: { type: ACCOUNT_TYPES.PERSONAL },
    },
    /*-- flow update --*/
    values: initialValues ?? undefined,
    mode: CONSTANTS.COMMON.VALIDATE_RULE,
  });

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  useEffect(() => {
    /*-- flow update --*/
    if (initialValues) {
      const numOfDays = countNumberOfDays(initialValues?.travelDate?.fromDate, initialValues?.travelDate?.toDate);
      /*-- set tab view for travel section --*/
      setIndex(numOfDays > 1 ? 1 : 0);
    } else {
      let defaultRequestType = type || REQUEST_TYPE.other;
      if (
        shouldShowWalkthrough &&
        shouldShowWalkthrough !== "false" &&
        currentChildrenActionCode === CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME
      ) {
        defaultRequestType = REQUEST_TYPE.travel;
      }
      setValue("expenseRequestType", defaultRequestType);
    }
  }, []);
  const travelDate = watch("travelDate", null);

  const perDiemNotSetupCategoryIfExist = watch("travel.perDiem", 0);
  const approverId = watch("approverId", "");
  const expenseRequestType = watch("expenseRequestType", REQUEST_TYPE.travel);
  const numberOfDays =
    travelDate?.fromDate && travelDate?.toDate && countNumberOfDays(travelDate.fromDate, travelDate.toDate);
  const allowances = watch("allowances", []);
  const passengers = watch("passengers", []);
  const totalAmount = useMemo(() => {
    const totalAllowance = allowances.reduce((total: number, item) => {
      if (
        [ALLOWANCE_TYPE.PERDIEM, ALLOWANCE_TYPE.BOOKING_FLIGHT, ALLOWANCE_TYPE.BOOKING_BUS].includes(item.type) &&
        passengers.length > 1
      ) {
        return total + (item?.amount ?? 0) * passengers.length;
      }
      return total + item?.amount;
    }, 0);
    if (expenseRequestType === REQUEST_TYPE.travel) {
      return perDiemNotSetupCategoryIfExist * numberOfDays + totalAllowance;
    }
    return totalAllowance;
  }, [allowances, numberOfDays, perDiemNotSetupCategoryIfExist, expenseRequestType, passengers?.length]);
  const cashAdvanceAmount = watch("cashAdvance.amount");
  const hasCashAdvance = watch("hasCashAdvance");
  const [index, setIndex] = useState(0);

  const onChangeTab = (index) => {
    setIndex(index);
    if (index === 0) {
      setValue("travelDate.toDate", travelDate.fromDate);
    }
  };
  const flightBooking = watch("flightBooking");
  const transportationBooking = watch("transportationBooking");
  const hotelBooking = watch("hotelBooking");
  /*--- expense policy travel for employee --*/
  const limitation = expensePolicyTravel?.expEmployeeExpensePolicyTravel?.limitation;
  //const flightBooking = expensePolicyTravel?.expEmployeeExpensePolicyTravel?.flightBooking;
  //const transportationBooking = expensePolicyTravel?.expEmployeeExpensePolicyTravel?.transportationBooking;
  //const hotelBooking = expensePolicyTravel?.expEmployeeExpensePolicyTravel?.hotelBooking;
  const handleFetchExpensePolicyTravel = async () => {
    const rs = await onFetchExpensePolicyTravel();
    const { flightBooking, transportationBooking, hotelBooking } = rs?.data?.expEmployeeExpensePolicyTravel;
    const isUpdate = Boolean(initialValues);

    /*-- TMG group use case ---*/
    if (requestSetting?.travel?.withPassengerInfo && isEmpty(getValues("passengers"))) {
      setValue("passengers", [{ fullname: "" }]);
      // if require passenger info --> auto set enablePassenger is true
      setValue("enablePassenger", requestSetting?.travel?.requirePassengerInfo);
    }
    if (requestSetting?.hotelBooking?.withHotelInfo && !getValues("hotelBooking.location")) {
      setValue("hotelBooking.isOn", requestSetting?.hotelBooking?.requireHotelInfo);
    }
    /*--- end ---*/

    if (flightBooking?.isEnabled) {
      isUpdate
        ? setValue("flightBooking.isEnabled", true)
        : setValue("flightBooking", {
            ...getValues("flightBooking"),
            isEnabled: true,
            isRoundTrip: true,
            expenseCategory: {
              expenseCategoryId: flightBooking?.expenseCategory?.expenseCategoryId,
              title: flightBooking?.expenseCategory?.title,
              titleEn: flightBooking?.expenseCategory?.titleEn,
            },
          });
    }
    if (transportationBooking?.isEnabled) {
      isUpdate
        ? setValue("transportationBooking.isEnabled", true)
        : setValue("transportationBooking", {
            ...getValues("transportationBooking"),
            isEnabled: true,
            isRoundTrip: true,
            expenseCategory: {
              expenseCategoryId: transportationBooking?.expenseCategory?.expenseCategoryId,
              title: transportationBooking?.expenseCategory?.title,
              titleEn: transportationBooking?.expenseCategory?.titleEn,
            },
          });
    }
    if (hotelBooking?.isEnabled) {
      isUpdate
        ? setValue("hotelBooking.isEnabled", true)
        : setValue("hotelBooking", {
            ...getValues("hotelBooking"),
            isEnabled: true,
            expenseCategory: {
              expenseCategoryId: hotelBooking?.expenseCategory?.expenseCategoryId,
              title: hotelBooking?.expenseCategory?.title,
              titleEn: hotelBooking?.expenseCategory?.titleEn,
            },
          });
    }
  };
  useEffect(() => {
    if (expenseRequestType === REQUEST_TYPE.travel) {
      handleFetchExpensePolicyTravel();
    }
  }, [expenseRequestType]);

  /*--- end ---*/

  const submit = (type: ExpenseRequestFormAction) => (values: ExpenseRequestFormValues) => {
    if (approvalTypeRequest === APPROVAL_TYPE.MANUAL && type === ExpenseRequestFormAction.SUBMIT) {
      approverChooserModalRef?.current?.present();
      return;
    }
    approverChooserModalRef?.current?.close();
    onSubmit(type, values);
  };
  const submitManual = (type: ExpenseRequestFormAction) => (values: ExpenseRequestFormValues) => {
    approverChooserModalRef?.current?.close();
    onSubmit(type, values);
  };

  /*-- scroll to first error when user submit form --*/
  const scrollViewRef = useRef<any>();
  const positionInput = useRef({});
  const handleSetPositionInput = (key) => (offsetY) => {
    positionInput.current[key] = offsetY;
  };
  const onError: SubmitErrorHandler<ExpenseRequestFormValues> = (errors) => {
    const fieldFirstError = Object.keys(errors)?.[0];
    const fieldInsideFirstError = Object.keys(errors[fieldFirstError])?.[0];
    const position = positionInput?.current?.[`${fieldFirstError}.${fieldInsideFirstError}`];

    // in the case this field is object
    if (position) {
      scrollViewRef?.current?.scrollTo({
        y: position,
        animated: true,
      });
    } else if (fieldFirstError && positionInput?.current?.[fieldFirstError]) {
      scrollViewRef?.current?.scrollTo({
        y: positionInput.current[fieldFirstError],
        animated: true,
      });
    }
    return errors;
  };
  /*--- end ---*/
  const handleAutoAddAllowances = (type: BOOKING_TYPE) => {
    switch (type) {
      case BOOKING_TYPE.FLIGHT:
        return airLineTicketRef?.current?.handleLoadAllowances?.();
      case BOOKING_TYPE.TRANSPORTATION:
        return busTicketRef?.current?.handleLoadAllowances?.();
      case BOOKING_TYPE.HOTEL:
        return hotelBookingRef?.current?.handleLoadAllowances?.();
      default:
        break;
    }
  };

  const routes = useMemo(() => {
    return [
      {
        key: "single",
        title: t("one_day"),
        component: (
          <CopilotStepCustom
            title={"walkthrough_request_title_step3"}
            text={"walkthrough_request_description_step3"}
            order={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.Step3}
            image={BizziBotSticker02}
            walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME}
            autoScroll={true}
            offsetX={10}
          >
            <TravelSelector
              control={control}
              setValue={setValue}
              getValues={getValues}
              mode={DatePickerMode.SINGLE}
              errors={errors}
              watch={watch}
              limitationEnable={limitation?.isEnabled}
              handleAutoAddAllowances={handleAutoAddAllowances}
            />
          </CopilotStepCustom>
        ),
      },
      {
        key: "range",
        title: t("multiple_day"),
        component: (
          <TravelSelector
            control={control}
            setValue={setValue}
            getValues={getValues}
            mode={DatePickerMode.RANGE}
            errors={errors}
            watch={watch}
            limitationEnable={limitation?.isEnabled}
            handleAutoAddAllowances={handleAutoAddAllowances}
          />
        ),
      },
    ];
  }, [control, setValue, getValues, errors, watch, limitation?.isEnabled, handleAutoAddAllowances]);

  const handleChooseType = (item, onSelect) => {
    onSelect();
    // remove allowances
    if (expenseRequestType === REQUEST_TYPE.travel && item?.value === REQUEST_TYPE.other) {
      setValue(
        "allowances",
        getValues("allowances")?.filter((item) => item?.type === ALLOWANCE_TYPE.USER_REQUEST)
      );
    } else if (expenseRequestType === REQUEST_TYPE.purchase || item?.value === REQUEST_TYPE.purchase) {
      // remove allowances
      setValue("allowances", []);
    }
  };

  return (
    <>
      <KeyboardAwareScrollViewCustom
        innerRef={(ref) => (scrollViewRef.current = ref)}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: footerHeight + 8 }}
      >
        <View style={styles.container}>
          {isSupportCurrency && (
            <>
              <View style={styles.section}>
                <CurrencySection
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                  getValues={getValues}
                />
              </View>
              <Line size="lg" />
            </>
          )}
          <View style={styles.section}>
            <AppText style={[Fonts.SentenceSubTitleXLarge, styles.sectionTitle]}>{t("expense_info")}</AppText>
            <CopilotStepCustom
              title={"walkthrough_request_title_step2"}
              text={"walkthrough_request_description_step2"}
              order={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.Step2}
              image={BizziBotSticker01}
              walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME}
              autoScroll={true}
              offsetX={-10}
            >
              <PickerCustom
                autoOpen={!initialValues && !type && requestTypes?.length > 1 && !shouldShowWalkthrough}
                style={styles.selectType}
                control={control}
                required
                name="expenseRequestType"
                label={t("type_request")}
                title={t("expense_request_type")}
                options={expenseRequestTypes}
                renderPickerItem={(item, onSelect) => {
                  return (
                    <TouchableOpacity
                      key={item?.value as string}
                      style={styles.requestTypeItem}
                      onPress={() => handleChooseType(item, onSelect)}
                    >
                      {getRequestIconByType(item?.value as REQUEST_TYPE)}
                      <AppText style={styles.requestTypeTitle}>{item?.label}</AppText>
                    </TouchableOpacity>
                  );
                }}
              />
              <TextInputCustom
                style={styles.textInput}
                name="title"
                containerRef={scrollViewRef}
                setPosition={handleSetPositionInput("title")}
                control={control}
                label={t("title")}
                placeholder={t("title")}
                error={errors.title}
                rules={{ required: t("required"), minLength: { value: 5, message: t("title_min", { value: 5 }) } }}
              />
              {FEATURE_TEAM_MGMT && (
                <CompanyTeamSelect
                  autoFillCompanyDefault={!initialValues}
                  style={styles.selectInput}
                  onClear={() => {
                    setValue("companyTeam", null);
                  }}
                  name="companyTeam"
                  control={control}
                  setValue={setValue}
                />
              )}
            </CopilotStepCustom>
            {FEATURE_BUDGET_MGMT && (
              <DatePickerCustom
                mode="month"
                name="budgetDate"
                control={control}
                label={t("budget_date")}
                placeholder={t("budget_date")}
                style={styles.selectInput}
                minimumDate={allowPastTimeOnExpenseRequestCreation ? undefined : dayjs().toDate()}
              />
            )}
            <TextInputCustom
              style={styles.description}
              name="description"
              containerRef={scrollViewRef}
              setPosition={handleSetPositionInput("description")}
              control={control}
              label={requestSetting?.general?.requireDescription ? t("description") : t("description_optional")}
              placeholder={t("description")}
              multiline
              autoHeight
              error={errors.description}
              rules={
                requestSetting?.general?.requireDescription
                  ? { required: t("required"), maxLength: { value: 1000 } }
                  : { maxLength: { value: 1000 } }
              }
            />
            {FEATURE_EXP_EXPENSE_REQUEST_NOTE && (
              <TextInputCustom
                name="note"
                control={control}
                label={t("note")}
                placeholder={t("note")}
                multiline
                autoHeight
                error={errors.note}
                rules={{ maxLength: { value: 1000 } }}
              />
            )}
          </View>
          <Line size="lg" />
          {expenseRequestType === REQUEST_TYPE.travel && (
            <>
              <View
                style={styles.travelSection}
                onLayout={(event) => {
                  handleSetPositionInput("travel")(event.nativeEvent.layout.y);
                  handleSetPositionInput("travelDate")(event.nativeEvent.layout.y);
                }}
              >
                <AppText style={[Fonts.SentenceSubTitleXLarge, styles.travelSectionTitle]}>
                  {t("travel_section")}
                </AppText>
                <TabViewInsideScrollView
                  tabBarStyle={styles.tabBarStyle}
                  index={index}
                  setIndex={onChangeTab}
                  routes={routes}
                />
              </View>
              <Line size="lg" />
            </>
          )}
          {expenseRequestType === REQUEST_TYPE.travel && requestSetting?.travel?.withPassengerInfo && (
            <>
              <PassengerListSection
                errors={errors}
                containerRef={scrollViewRef}
                setPosition={handleSetPositionInput}
                control={control}
                setValue={setValue}
                watch={watch}
              />
              <Line size="lg" />
            </>
          )}
          {expenseRequestType === REQUEST_TYPE.travel && requestSetting?.travel?.withPassengerInfo && (
            <>
              <PassengerListSection
                errors={errors}
                containerRef={scrollViewRef}
                setPosition={handleSetPositionInput}
                control={control}
                setValue={setValue}
                watch={watch}
              />
              <Line size="lg" />
            </>
          )}
          {expenseRequestType === REQUEST_TYPE.travel && (
            <>
              <CopilotStepCustom
                title={"walkthrough_request_title_step4"}
                text={"walkthrough_request_description_step4"}
                order={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.Step4}
                image={BizziBotSticker03}
                walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME}
                autoScroll={true}
                offsetX={10}
              >
                <PerDiemInfoSection watch={watch} />
              </CopilotStepCustom>
              <Line size="lg" />
            </>
          )}
          {expenseRequestType === REQUEST_TYPE.travel &&
            (flightBooking?.isEnabled || transportationBooking?.isEnabled || hotelBooking?.isEnabled) && (
              <>
                <CopilotStepCustom
                  title={"walkthrough_request_title_step5"}
                  text={"walkthrough_request_description_step5"}
                  order={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.Step5}
                  image={BizziBotSticker01}
                  walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME}
                  offsetX={10}
                  autoScroll={true}
                  style={{ gap: 8 }}
                >
                  {flightBooking?.isEnabled && (
                    <>
                      <AirlineTicketSection
                        ref={airLineTicketRef}
                        containerRef={scrollViewRef}
                        setPosition={handleSetPositionInput}
                        getValues={getValues}
                        setValue={setValue}
                        name="flightBooking"
                        control={control}
                        errors={errors}
                        watch={watch}
                        setDateModalInfo={setDateModalInfo}
                        isUpdate={isUpdate}
                      />
                    </>
                  )}
                  {transportationBooking?.isEnabled && (
                    <>
                      <BusTicketSection
                        containerRef={scrollViewRef}
                        ref={busTicketRef}
                        setPosition={handleSetPositionInput}
                        getValues={getValues}
                        setValue={setValue}
                        name="transportationBooking"
                        control={control}
                        errors={errors}
                        watch={watch}
                        setDateModalInfo={setDateModalInfo}
                      />
                    </>
                  )}
                  {hotelBooking?.isEnabled && (
                    <>
                      <HotelSection
                        containerRef={scrollViewRef}
                        ref={hotelBookingRef}
                        setPosition={handleSetPositionInput}
                        getValues={getValues}
                        setValue={setValue}
                        name="hotelBooking"
                        control={control}
                        errors={errors}
                        watch={watch}
                      />
                    </>
                  )}
                </CopilotStepCustom>
                <Line size="lg" />
              </>
            )}
          <CopilotStepCustom
            title={"walkthrough_request_title_step6"}
            text={
              type === REQUEST_TYPE.travel
                ? "walkthrough_request_description_step6"
                : "walkthrough_other_request_description_step6"
            }
            order={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.Step6}
            image={BizziBotSticker02}
            walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME}
            style={styles.section}
            offsetX={10}
            autoScroll={true}
          >
            <AppText style={[Fonts.SentenceSubTitleXLarge, styles.sectionTitle]}>
              {expenseRequestType === REQUEST_TYPE.purchase ? t("product_service_title") : t("budget_section")}
            </AppText>
            <AllowancesSection watch={watch} setValue={setValue} control={control} getValues={getValues} />
          </CopilotStepCustom>

          {FEATURE_EXP_CASH_ADVANCE && (
            <>
              <Line size="lg" />
              <CopilotStepCustom
                title={"walkthrough_request_title_step7"}
                text={
                  type === REQUEST_TYPE.travel
                    ? "walkthrough_request_description_step7"
                    : "walkthrough_other_request_description_step7"
                }
                order={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.Step7}
                image={BizziBotSticker03}
                walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME}
                offsetX={10}
                autoScroll={true}
              >
                <AdvancePaymentSection
                  containerRef={scrollViewRef}
                  setPosition={handleSetPositionInput}
                  getValues={getValues}
                  setValue={setValue}
                  name="advancePayment"
                  control={control}
                  errors={errors}
                  watch={watch}
                  totalAmount={totalAmount}
                />
              </CopilotStepCustom>
            </>
          )}
          <Line size="lg" />
          <View style={styles.section}>
            <AppText style={[Fonts.SentenceSubTitleXLarge, styles.sectionTitle]}>{t("other_assets")}</AppText>
            <UploadFiles title={t("other_assets")} name="attachments" control={control} />
          </View>
        </View>
      </KeyboardAwareScrollViewCustom>
      <FloatFooter onLayout={onLayoutFooter}>
        <View style={styles.totalAmountInfo}>
          <View style={styles.amountRow}>
            <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("total")}</AppText>
            <CurrencyText style={Fonts.NumericN400} color={Colors.success50}>
              {totalAmount}
            </CurrencyText>
          </View>
          {FEATURE_EXP_CASH_ADVANCE && (
            <>
              <View style={styles.amountRow}>
                <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("advance")}</AppText>
                <CurrencyText style={Fonts.NumericN300}>{hasCashAdvance ? cashAdvanceAmount : 0}</CurrencyText>
              </View>
              {company_id === "0edf448f-030f-4bb8-b9a5-cba16c55c891" && (
                //TODO: MAKE A COMPANY WHITELIST
                //ONLY DISPLAY FOR ONE_CLICK
                <View style={styles.amountRow}>
                  <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("anticipated_expenses_after_advances")}</AppText>
                  <CurrencyText style={Fonts.NumericN300}>
                    {hasCashAdvance ? Math.max(0, totalAmount - cashAdvanceAmount) : 0}
                  </CurrencyText>
                </View>
              )}
            </>
          )}
        </View>
        <View style={styles.wrapperButtonContainer}>
          <Button
            eventName={initialValues ? EVENT.EXPENSE.UPDATED : EVENT.REQUEST.SAVED_DRAFT}
            disabled={disabledButton}
            type="secondary"
            onPress={handleSubmit(submit(ExpenseRequestFormAction.SAVE_DRAFT), onError)}
            style={styles.buttonSave}
          >
            {t("save")}
          </Button>
          <Button
            eventName={initialValues ? EVENT.REQUEST.SUBMITTED : EVENT.REQUEST.CREATED}
            disabled={disabledButton || !totalAmount}
            type="primary"
            onPress={handleSubmit(submit(ExpenseRequestFormAction.SUBMIT), onError)}
            style={styles.buttonSave}
          >
            {t("submit")}
          </Button>
        </View>
      </FloatFooter>
      <ApproverChooserModal
        title={t("select_approver")}
        description={t("select_approver_description")}
        ref={approverChooserModalRef}
        approverId={approverId}
        onSubmit={handleSubmit(submitManual(ExpenseRequestFormAction.SUBMIT))}
        onApproverId={(approverId: string) => {
          setValue("approverId", approverId);
        }}
        onClose={() => setValue("approverId", null)}
      />
      <DateTimePickerModal
        isVisible={dateModalInfo?.visible}
        pickerComponentStyleIOS={{ height: 370 }}
        minimumDate={dateModalInfo?.minimumDate ? dayjs(dateModalInfo?.minimumDate).toDate() : undefined}
        maximumDate={dateModalInfo?.maximumDate ? dayjs(dateModalInfo?.maximumDate).toDate() : undefined}
        onConfirm={(date) => {
          setDateModalInfo((prevState) => ({ ...prevState, visible: false, date }));
          if (Array.isArray(dateModalInfo?.field)) {
            dateModalInfo?.field?.forEach((item) => {
              setValue(item as keyof ExpenseRequestFormValues, new Date(date) as any, {
                shouldValidate: true,
              });
            });
          } else {
            setValue(dateModalInfo?.field as keyof ExpenseRequestFormValues, new Date(date) as any, {
              shouldValidate: true,
            });
          }
        }}
        date={dateModalInfo?.date ? dayjs(dateModalInfo.date).toDate() : new Date()}
        display="inline"
        onCancel={() => setDateModalInfo((prevState) => ({ ...prevState, visible: false }))}
        accentColor={Colors.primary50}
        locale={i18n.language === LanguageStatus.VN ? "vi-VN" : "en-US"}
        confirmTextIOS={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          t("common:confirm")
        }
        cancelTextIOS={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          t("common:cancel")
        }
      />
    </>
  );
};
export default ExpenseRequestForm;

const styles = StyleSheet.create({
  container: { backgroundColor: Colors.grayscale05, flex: 1 },
  textInput: { marginVertical: 10 },
  description: { marginTop: 2, marginBottom: 10 },
  selectInput: { marginVertical: 8 },
  selectType: { marginTop: 8 },
  section: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 15,
    paddingBottom: 10,
  },
  requestTypeItem: { paddingVertical: 16, flexDirection: "row", gap: 10, marginHorizontal: 20 },
  requestTypeTitle: { ...Fonts.BodyLarge, flex: 1 },
  sectionTitle: { marginBottom: 10 },
  tabBarStyle: { marginBottom: 16 },
  travelSection: {
    backgroundColor: Colors.white,
    paddingTop: 20,
    paddingBottom: 10,
  },
  travelSectionTitle: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, marginBottom: 20 },
  wrapperButtonContainer: {
    marginTop: 3,
    backgroundColor: Colors.while,
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "center",
    gap: 8,
  },
  buttonSave: {
    flex: 1,
  },
  totalAmountInfo: { marginBottom: 4 },
  amountRow: { flexDirection: "row", gap: 8, alignItems: "center", marginBottom: 3 },
  flex: { flex: 1 },
});
