import maxBy from "lodash/maxBy";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";

import { BizziBotWarning2, ErrorInvoiceIcon, WarningCircleIcon } from "assets/images/svg/icons";
import {
  AlertNotification,
  AlertNotificationHandle,
  ApproverChooserModal,
  AppText,
  BottomSheetModalCustomMethods,
  Button,
  CurrencyText,
  EmptyData,
  FloatFooter,
  ToastManager,
  TopTabLineView,
  TouchableOpacityCustom,
} from "components";
import { ReportManageProvider } from "../context/ReportManageProvider";
import { Colors, Fonts, FontTypes } from "theme";

import { Flex } from "@ant-design/react-native";
import {
  APPROVAL_STATUS,
  APPROVAL_TYPE,
  EXPENSE_REPORT_STATUS,
  INVOICE_TYPE,
  RESTRICTION_BUDGET_TYPE,
  TRACKING_OBJECT_TYPE,
} from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import useExpenseApproveReport from "hooks/reports/useExpenseApproveReport";
import SCREEN_NAME, { SCREEN_NAME_TRACKING } from "navigation/ScreenName";
import { useExpExpenseReportDetailsQuery } from "hooks/reports/useExpExpenseReportDetailsQuery";
import { useExpSubmitExpenseReportMutation } from "hooks/reports/useExpSubmitExpenseReportMutation";
import { useApprovalTypeQuery } from "screens/Report/hooks/useApprovalTypeQuery";
import { useValidateExpenseReportAmountMutation } from "hooks/useValidateExpenseReportAmount";
import i18n from "i18next";
import CancelExpenseReportModal from "./components/CancelExpenseReportModal";
import RejectExpenseReportModal from "./components/RejectExpenseReportModal";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import uniq from "lodash/uniq";
import { MobileExpenseReportDetailsQuery, MobileValidateExpenseReportAmountMutation } from "types";
import { ApolloError } from "@apollo/client";
import { Route } from "react-native-tab-view";
import DetailTab from "./components/DetailTab/DetailTab";
import ExpenseTab from "./components/ExpenseTab/ExpenseTab";
import ActivityLogTab from "./components/ActivityLogTab/ActivityLogTab";
import { goBack } from "navigation/RootNavigation";
import { EVENT } from "constants/Tracking";
import { analyticService, withTrackingSession } from "services/AnalyticsService";
import { executeRouteFunction } from "utils/route";
import ButtonApproveReport from "./components/ButtonApproveReport";
import { useExpPartialApproveReport } from "screens/Report/hooks/useExpPartialApproveReport";
import { exchangeRateConverter } from "utils";
import { ExpenseHelper } from "utils/expense";

enum ApproverModalType {
  APPROVE = "APPROVE",
  RE_SUBMIT = "RE-SUBMIT",
}
type ReportDetailScreenProps = {
  route: any;
  navigation: any;
};

const ReportDetailScreen: FC<ReportDetailScreenProps> = ({ navigation, route }) => {
  const { expenseReportId } = route?.params ?? {};

  const { t } = useTranslation("app/screens/Report/ReportDetailScreen");
  const { user, switchCompany } = useAuth();

  const alertNotificationRef = useRef<AlertNotificationHandle>(null);
  const modalCancelRef = useRef<BottomSheetModalCustomMethods>(null);
  const modalRejectRef = useRef<BottomSheetModalCustomMethods>(null);
  const approverChooserModalRef = useRef<BottomSheetModalCustomMethods>(null);

  const [approverId, setApproverId] = useState(null);
  const [approverType, setApproverType] = useState<ApproverModalType>(ApproverModalType.APPROVE);
  const [disabledButton, setDisabledButton] = useState(false);
  const [index, setIndex] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [data, setData] = useState<MobileExpenseReportDetailsQuery["expExpenseReportDetails"]>(null);

  const { loading, refetch, onFetchReportDetail } = useExpExpenseReportDetailsQuery({
    variables: { expenseReportId },
    onCompleted: (data: MobileExpenseReportDetailsQuery) => {
      setData(data?.expExpenseReportDetails);
      switchCompany(data?.expExpenseReportDetails?.company?.companyId);
    },
  });
  const [submitExpenseReport] = useExpSubmitExpenseReportMutation();
  const [partialApproveReport] = useExpPartialApproveReport();
  const [validateExpenseReportAmount] = useValidateExpenseReportAmountMutation();
  const [FEATURE_EXP_REIMBURSABLE_EXPENSE, FEATURE_EXP_CASH_ADVANCE, FEATURE_EXP_PARTIAL_APPROVAL] =
    useIsFeaturesEnabled([
      FEATURE_FLAGS.FEATURE_EXP_REIMBURSABLE_EXPENSE,
      FEATURE_FLAGS.FEATURE_EXP_CASH_ADVANCE,
      FEATURE_FLAGS.FEATURE_EXP_PARTIAL_APPROVAL,
    ]);
  const [onFetchApprovalType] = useApprovalTypeQuery();

  /*--- this screen have 2 view: detail view for normal user and approve view for approver */
  const [isApprovedOrRejectView, canApproveWithoutEInvoice, approvalType] = useMemo(() => {
    const approvalRules = data?.expenseApprovalRules ?? [];
    const lastApprovalRule = maxBy(approvalRules, ({ order }) => order);
    const userApprovalRule = approvalRules.find((rule) => {
      const ruleEmployee = rule.expenseApprovalRuleEmployees.find(
        (i) => i?.employee?.userId === user?.id && rule.status === APPROVAL_STATUS.AWAITING
      );

      return Boolean(ruleEmployee);
    });

    // Can approve if current approval rule is not the last one
    const canApproveWithoutEInvoice =
      userApprovalRule?.expenseApprovalRuleId !== lastApprovalRule?.expenseApprovalRuleId;
    return [Boolean(userApprovalRule), canApproveWithoutEInvoice, userApprovalRule?.approvalType];
  }, [data]);
  const isReportCashAdvance = FEATURE_EXP_CASH_ADVANCE && data?.expenses?.[0]?.expenseRequest?.cashAdvances?.[0];
  const isCanPartialApprove = useMemo(() => {
    return (
      FEATURE_EXP_PARTIAL_APPROVAL &&
      approvalType === APPROVAL_TYPE.AUTO &&
      data?.expenses?.length > 1 &&
      !isReportCashAdvance
    );
  }, [FEATURE_EXP_PARTIAL_APPROVAL, data, approvalType, isReportCashAdvance]);

  const foreignExchangeRate: number | undefined = data?.customFields.exchange_rate;

  const { totalAmountWithVat, payableAmount } = ExpenseHelper.getExpensesRelevantAmounts(data?.expenses);

  const routes = [
    { key: "detail", title: t("detail") },
    { key: "expense", title: t("expense") },
    { key: "activity", title: t("activity") },
  ];
  const isLoading = loading && !data;

  const onApprovePartial = async (reason, approvedExpenseIds) => {
    try {
      alertNotificationRef?.current?.loading();
      const rs = await partialApproveReport({
        variables: { input: { expenseReportId: data?.expenseReportId, approvedExpenseIds, reason } },
      });
      alertNotificationRef?.current?.close();
      if (rs?.data?.expPartialApproveReport?.success) {
        ToastManager.success(t("description_approval_success"));
        executeRouteFunction(route, "onRefreshData");
        goBack();
        return;
      }
      throw new Error();
    } catch (e) {
      alertNotificationRef.current?.error({
        title: t("approve_report_failed"),
        description: t("internal_error"),
      });
    }
  };
  const handleApprove = (reason, approvedExpenseIds) => {
    setDisabledButton(true);
    // in the case: paper invoice -> user must approve in web system
    const isExistPaperInvoice = data?.expenses?.some((expense) => expense?.invoiceType === INVOICE_TYPE.PAPER_INVOICE);
    if (isExistPaperInvoice && !canApproveWithoutEInvoice) {
      alertNotificationRef?.current?.info({
        icon: <WarningCircleIcon />,
        title: t("need_approve_report_by_web_title"),
        description: t("need_approve_report_by_web"),
        confirmText: t("got_it"),
        onConfirm: () => alertNotificationRef?.current?.close(),
      });
      setDisabledButton(false);
      return;
    }
    // flow partial approve report
    if (isCanPartialApprove && approvedExpenseIds?.length && approvedExpenseIds?.length < data?.expenses?.length) {
      onApprovePartial(reason, approvedExpenseIds);
      setDisabledButton(false);
      return;
    }
    // flow normal approve report
    onApproveReport(reason);
  };

  const { approvalReport, approvalReportWithAddApproval } = useExpenseApproveReport();

  const handleApproveReportManual = () => {
    // in the case: user is last approver -> check Exist Invoice Paper and No Invoice
    approverChooserModalRef?.current?.close();
    if (!approverId) {
      const isExistPaperInvoice = data?.expenses?.some(
        (expense) => expense?.invoiceType === INVOICE_TYPE.PAPER_INVOICE
      );
      if (isExistPaperInvoice) {
        alertNotificationRef?.current?.info({
          icon: <WarningCircleIcon />,
          title: t("need_approve_report_by_web_title"),
          description: t("need_approve_report_by_web"),
          confirmText: t("got_it"),
          onConfirm: () => alertNotificationRef?.current?.close(),
        });
        return;
      }
    }
    onApproveReport();
  };

  const onApproveReport = async (reason?: string) => {
    try {
      setDisabledButton(true);
      let results;
      alertNotificationRef?.current?.loading();

      //Approval process
      if (approverId) {
        results = await approvalReportWithAddApproval({
          reportId: expenseReportId,
          approver_id: approverId,
        });
      } else {
        results = await approvalReport({
          variables: {
            reportId: expenseReportId,
            reason,
          },
        });
      }

      //Handle approve
      if (!results?.data?.expenseApproveReport?.success && !results?.data?.expenseAddReportManualApprover?.success) {
        throw new Error();
      }
      alertNotificationRef?.current?.close();
      ToastManager.success(t("description_approval_success"));
      goBack();
      onRefreshData?.(route.params.expenseReportId);
      setDisabledButton(false);
      const expenseParamsValidate = data?.expenses
        ?.filter((item) => item?.totalAmountWithoutVat > 0)
        .map((item) => ({
          expenseDate: item?.expenseDate,
          spendingAmount: item?.totalAmountWithoutVat,
          spendingAmountWithVat: item?.totalAmountWithVat,
          spendingAmountWithoutVat: item?.totalAmountWithoutVat,
          expenseCategoryId: item?.expenseCategory?.expenseCategoryId,
          expenseRequestId: item?.expenseRequestId ?? undefined,
          companyTeamId: item?.companyTeamId ?? undefined,
        }));
      const rs = await validateExpenseReportAmount({
        variables: {
          input: {
            companyId: user?.company?.company_id,
            expenses: expenseParamsValidate,
          },
        },
      });
      const validateReport = rs?.data?.validateExpenseReportAmount;
      const companyTeam = data?.expenseReportsTeams?.[0]?.companyTeam;
      const expenseApprovalRules = data?.expenseApprovalRules ?? [];
      const approvalRulesWaitingIndex = expenseApprovalRules.findIndex(
        (item) =>
          item?.status === APPROVAL_STATUS.AWAITING &&
          item?.expenseApprovalRuleEmployees?.some((employee) => employee?.employeeId === user?.employee_id)
      );
      const isFinalApprovalRule = approvalRulesWaitingIndex === expenseApprovalRules.length - 1;
      analyticService.logEvent({
        sessionId: SCREEN_NAME.ReportDetailScreen,
        name: EVENT.REPORT.APPROVED_SUCCESS,
        properties: {
          current_screen: SCREEN_NAME_TRACKING.ReportDetailScreen,
          object_id: expenseReportId,
          object_type: TRACKING_OBJECT_TYPE.REPORT,
          is_final: isFinalApprovalRule,
          over_budgets: validateReport?.budgets
            ?.filter((item) => !item?.isValid)
            ?.map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: item?.overBudgetAmount,
              expense_date: item?.expenseDate,
            })),
          over_requests: validateReport?.expenseRequests
            ?.filter((item) => !item?.isValid)
            ?.map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: item?.overRequestAmount,
            })),
        },
      });
    } catch (error) {
      setDisabledButton(false);
      const errorTitle = t("approve_report_failed");
      let errorMessage = t("internal_error");
      switch (error?.message) {
        case SERVER_ERROR_CODE.NOT_OPEN_TO_APPROVE: {
          errorMessage = t("not_open_to_approve");
          break;
        }
        case SERVER_ERROR_CODE.ITEM_CANCELLED: {
          errorMessage = t("report_is_cancelled");
          break;
        }
        case SERVER_ERROR_CODE.NO_PERMISSION: {
          errorMessage = t("no_permission");
          break;
        }
      }
      analyticService.logEvent({
        sessionId: SCREEN_NAME.ReportDetailScreen,
        name: EVENT.REPORT.APPROVED_FAILED,
        properties: {
          message: errorMessage,
          error: JSON.stringify(error),
        },
      });
      alertNotificationRef.current?.error({
        title: errorTitle,
        description: errorMessage,
      });
    }
  };
  const onRefreshData = (expenseReportId = undefined) => {
    refetch();
    route?.params?.onRefreshData?.(expenseReportId);
  };
  const handleError = (error: ApolloError) => {
    setDisabledButton(false);
    let message = i18n.t("common:internal_error");
    if (error?.message === SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW) {
      message = t("description_error_send_report");
    } else if (error?.graphQLErrors?.[0]?.extensions?.code === SERVER_ERROR_CODE.BAD_USER_INPUT) {
      message = error?.graphQLErrors?.[0]?.message;
    }

    const noApprovalFlowError = [
      SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW,
      SERVER_ERROR_CODE.EXPENSE_UNSUPPORTED_APPROVAL_METHOD,
    ].includes(error?.message as SERVER_ERROR_CODE);
    alertNotificationRef.current?.error({
      title: t("re_submit_failed"),
      description: message,
      confirmText: noApprovalFlowError ? t("view_approval_flow") : undefined,
      onConfirm: noApprovalFlowError
        ? () => {
            alertNotificationRef?.current?.close();
            (navigation as any).replace(SCREEN_NAME.ApprovalFlowScreen, { index: 1 });
          }
        : undefined,
    });
  };
  const onValidateBudget = async () => {
    try {
      approverChooserModalRef?.current?.close();
      setDisabledButton(true);
      alertNotificationRef?.current?.loading();
      const expenseParamsValidate = data?.expenses
        ?.filter((item) => item?.totalAmountWithoutVat > 0)
        .map((item) => ({
          expenseDate: item?.expenseDate,
          spendingAmount: item?.totalAmountWithoutVat,
          spendingAmountWithVat: item?.totalAmountWithVat,
          spendingAmountWithoutVat: item?.totalAmountWithoutVat,
          expenseCategoryId: item?.expenseCategory?.expenseCategoryId,
          expenseRequestId: item?.expenseRequestId ?? undefined,
          companyTeamId: item?.companyTeamId ?? undefined,
        }));
      const rs = await validateExpenseReportAmount({
        variables: {
          input: {
            companyId: user?.company?.company_id,
            expenses: expenseParamsValidate,
          },
        },
      });
      setDisabledButton(false);
      const validateReport = rs?.data?.validateExpenseReportAmount;
      const isValidBudget =
        validateReport?.expenseRequests?.every(
          (item) => item?.isValid || item?.restrictionType === RESTRICTION_BUDGET_TYPE.ACCEPT
        ) &&
        validateReport?.budgets?.every(
          (item) => item?.isValid || item?.restrictionType === RESTRICTION_BUDGET_TYPE.ACCEPT
        );
      if (!isValidBudget) {
        const isRestrict =
          validateReport?.expenseRequests?.some(
            (item) => !item?.isValid && item?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT
          ) ||
          validateReport?.budgets?.some(
            (item) => !item?.isValid && item?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT
          );
        if (isRestrict) {
          analyticService.logEvent({
            sessionId: SCREEN_NAME.ReportDetailScreen,
            name: EVENT.SYSTEM_ALERT.RESTRICT_REPORT_BUDGET,
            properties: {
              message: t("restrict_budget_report"),
              object_id: expenseReportId,
              object_type: TRACKING_OBJECT_TYPE.REPORT,
              restriction_type: RESTRICTION_BUDGET_TYPE.RESTRICT,
              over_budgets: validateReport?.budgets?.map((item) => ({
                expense_category_id: item?.expenseCategoryId,
                company_team_id: companyTeam?.companyTeamId,
                over_amount: item?.overBudgetAmount,
                expense_date: item?.expenseDate,
              })),
              over_requests: validateReport?.expenseRequests?.map((item) => ({
                expense_category_id: item?.expenseCategoryId,
                company_team_id: companyTeam?.companyTeamId,
                over_amount: item?.overRequestAmount,
              })),
            },
          });
          alertNotificationRef?.current?.info({
            icon: <BizziBotWarning2 />,
            title: t("budget_amount_warning_title"),
            description: t("restrict_budget_report"),
            confirmText: t("understood"),
            onConfirm: () => {
              alertNotificationRef?.current?.close();
            },
          });
          return;
        }
        const messages = [];
        if (
          validateReport?.budgets?.[0] &&
          !validateReport.budgets[0].isValid &&
          validateReport.budgets[0].restrictionType !== RESTRICTION_BUDGET_TYPE.ACCEPT
        ) {
          messages.push(validateReport.budgets[0].message);
        }
        if (
          validateReport?.expenseRequests?.[0] &&
          !validateReport.expenseRequests[0].isValid &&
          validateReport.expenseRequests[0].restrictionType !== RESTRICTION_BUDGET_TYPE.ACCEPT
        ) {
          messages.push(validateReport.expenseRequests[0].message);
        }
        messages.push(t("budget_amount_warning_context"));
        const companyTeam = data?.expenseReportsTeams?.[0]?.companyTeam;
        analyticService.logEvent({
          sessionId: SCREEN_NAME.ReportDetailScreen,
          name: EVENT.SYSTEM_ALERT.WARNING_REPORT_BUDGET,
          properties: {
            message: `${uniq(messages).join(".\n")}`,
            object_id: expenseReportId,
            object_type: TRACKING_OBJECT_TYPE.REPORT,
            restriction_type: RESTRICTION_BUDGET_TYPE.WARNING,
            over_budgets: validateReport?.budgets?.map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: item?.overBudgetAmount,
              expense_date: item?.expenseDate,
            })),
            over_requests: validateReport?.expenseRequests?.map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: item?.overRequestAmount,
            })),
          },
        });
        alertNotificationRef?.current?.confirm({
          icon: <BizziBotWarning2 />,
          title: t("budget_amount_warning_title"),
          description: `${uniq(messages).join(".\n")}`,
          cancelText: t("back"),
          onConfirm: () => onResubmitReport(validateReport, `${uniq(messages).join(".\n")}`),
        });
        return;
      }
      await onResubmitReport();
    } catch (e) {
      handleError(e);
    }
  };
  const onResubmitReport = async (
    validateReport?: MobileValidateExpenseReportAmountMutation["validateExpenseReportAmount"],
    message?: string
  ) => {
    try {
      approverChooserModalRef?.current?.close();
      setDisabledButton(true);
      alertNotificationRef?.current?.loading();
      await submitExpenseReport({
        variables: {
          expenseReportId: data?.expenseReportId,
          approverEmployeeId: approverId ?? undefined,
        },
      });
      setDisabledButton(false);
      alertNotificationRef?.current?.close();
      const companyTeam = data?.expenseReportsTeams?.[0]?.companyTeam;
      analyticService.logEvent({
        sessionId: SCREEN_NAME.ReportDetailScreen,
        name: EVENT.REPORT.SUBMITTED_SUCCESS,
        properties: {
          message,
          object_id: expenseReportId,
          object_type: TRACKING_OBJECT_TYPE.REPORT,
          over_budgets: validateReport?.budgets
            ?.filter((item) => !item?.isValid)
            .map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: item?.overBudgetAmount,
              expense_date: item?.expenseDate,
            })),
          over_requests: validateReport?.expenseRequests
            ?.filter((item) => !item?.isValid)
            .map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: item?.overRequestAmount,
            })),
        },
      });
      ToastManager.success(t("submit_expense_report_success"));
      goBack();
      executeRouteFunction(route, "onRefreshData");
    } catch (error) {
      analyticService.logEvent({
        sessionId: SCREEN_NAME.ReportDetailScreen,
        name: EVENT.REPORT.SUBMITTED_FAILED,
        properties: {
          error: JSON.stringify(error),
        },
      });
      handleError(error);
    }
  };
  const handleResubmitReportCashAdvance = () => {
    alertNotificationRef.current.confirm({
      icon: <BizziBotWarning2 />,
      title: t("send_report_advance_title"),
      description: (
        <View style={{ marginBottom: 20 }}>
          <View style={styles.descriptionItem}>
            <View style={styles.dotIcon} />
            <AppText>{t("send_report_advance_description1")}</AppText>
          </View>
          <View style={styles.descriptionItem}>
            <View style={styles.dotIcon} />
            {payableAmount >= 0 ? (
              <AppText>
                {t("company")} <AppText style={{ fontFamily: FontTypes.bold }}>{t("pays_you")}</AppText>{" "}
                {t("pays_you_amount")}{" "}
                <CurrencyText style={{ fontFamily: FontTypes.bold }}>{payableAmount}</CurrencyText>{" "}
                {t("according_this_report")}
              </AppText>
            ) : (
              <AppText>
                {t("you")} <AppText style={{ fontFamily: FontTypes.bold }}>{t("need_to_return")} </AppText>
                {t("advance_amount_return")}
                <CurrencyText style={{ fontFamily: FontTypes.bold }}>{payableAmount}</CurrencyText>{" "}
                {t("according_this_report")}
              </AppText>
            )}
          </View>
        </View>
      ),
      confirmText: t("continue"),
      cancelText: t("cancel"),
      onConfirm: () => {
        handleResubmitReport();
      },
      onCancel: () => {
        alertNotificationRef.current?.close();
      },
    });
    return;
  };
  const handleResubmitReport = async () => {
    try {
      setDisabledButton(true);
      alertNotificationRef?.current?.loading();
      const approvalType = await onFetchApprovalType();
      if (approvalType?.data?.expenseApprovalType?.approvalType === APPROVAL_TYPE.MANUAL) {
        setApproverType(ApproverModalType.RE_SUBMIT);
        approverChooserModalRef?.current?.present();
        setDisabledButton(false);
        setTimeout(() => {
          alertNotificationRef?.current?.close();
        }, 2000);
        return;
      }
      await onValidateBudget();
      setDisabledButton(false);
    } catch (e) {
      handleError(e);
    }
  };
  const renderScene = ({ route: innerRoute }: { route: Route }) => {
    switch (innerRoute.key) {
      case "detail":
        return (
          <DetailTab
            contentContainerStyle={{ paddingBottom: footerHeight + 8 }}
            loading={isLoading}
            isApproverView={isApprovedOrRejectView}
            expenseReport={data}
            refetch={refetch}
          />
        );
      case "expense":
        return (
          <ExpenseTab
            contentContainerStyle={{ paddingBottom: footerHeight + 8 }}
            loading={isLoading}
            expenseReport={data}
            enablePartialApproval={isCanPartialApprove}
          />
        );
      case "activity":
        return (
          <ActivityLogTab
            contentContainerStyle={{ paddingBottom: footerHeight + 8 }}
            loading={isLoading}
            logs={data?.expenseApprovalLogs}
          />
        );
      default:
        return null;
    }
  };
  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };
  const renderFooterButton = () => {
    if (isLoading) {
      return null;
    }
    const { status, companyEmployee, expenses } = data ?? {};
    const totalAmountSection = (
      <>
        <Flex justify="between" style={styles.totalAmountContainer}>
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
            {t("total_expense_amount")}
          </AppText>
          <TouchableOpacityCustom
            eventName={EVENT.REPORT.TAP_TOTAL_AMOUNT}
            disabled={!FEATURE_EXP_REIMBURSABLE_EXPENSE}
            onPress={() => setIndex(1)}
          >
            <CurrencyText
              style={Fonts.NumericN400}
              color={Colors.success100}
              foreignAmount={exchangeRateConverter(totalAmountWithVat, foreignExchangeRate)}
            >
              {totalAmountWithVat}
            </CurrencyText>
          </TouchableOpacityCustom>
        </Flex>
        {FEATURE_EXP_REIMBURSABLE_EXPENSE && (
          <Flex justify="between" style={styles.totalAmountContainer}>
            <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
              {payableAmount >= 0 ? t("company_must_pay") : t("employee_must_pay")}
            </AppText>
            <TouchableOpacityCustom eventName={EVENT.REPORT.TAP_TOTAL_AMOUNT} onPress={() => setIndex(1)}>
              <CurrencyText
                style={Fonts.NumericN300}
                foreignAmount={exchangeRateConverter(payableAmount, foreignExchangeRate)}
              >
                {payableAmount}
              </CurrencyText>
            </TouchableOpacityCustom>
          </Flex>
        )}
      </>
    );
    if (isApprovedOrRejectView && status === EXPENSE_REPORT_STATUS.SUBMITTED) {
      return (
        <FloatFooter onLayout={onLayoutFooter}>
          {totalAmountSection}
          <Flex direction="row">
            <Button
              eventName={EVENT.REPORT.REJECTED}
              style={styles.footerButtonLeft}
              disabled={disabledButton}
              onPress={() => modalRejectRef?.current?.present()}
              type="danger"
            >
              {t("reject")}
            </Button>
            <ButtonApproveReport
              disabled={disabledButton}
              onApprove={(reason, approvedExpenseIds) =>
                approvalType === APPROVAL_TYPE.MANUAL
                  ? approverChooserModalRef?.current?.present()
                  : handleApprove(reason, approvedExpenseIds)
              }
              isCanPartialApprove={isCanPartialApprove}
              expenseIds={expenses?.map((item) => item?.expenseId)}
              reportTitle={data?.title}
            />
          </Flex>
        </FloatFooter>
      );
    }
    const isOwnerReport = companyEmployee?.employeeId === user?.employee_id;
    if (isOwnerReport && status === EXPENSE_REPORT_STATUS.SUBMITTED) {
      return (
        <FloatFooter onLayout={onLayoutFooter}>
          {totalAmountSection}
          <Button eventName={EVENT.REPORT.CANCELED} onPress={() => modalCancelRef?.current?.present()} type="danger">
            {t("cancel_report")}
          </Button>
        </FloatFooter>
      );
    }
    if (
      isOwnerReport &&
      [EXPENSE_REPORT_STATUS.CANCEL, EXPENSE_REPORT_STATUS.REJECTED, EXPENSE_REPORT_STATUS.DRAFT].includes(status)
    ) {
      return (
        <FloatFooter onLayout={onLayoutFooter}>
          {totalAmountSection}
          <View style={styles.footerButtonContainer}>
            <Button
              eventName={EVENT.REPORT.TAP_UPDATE}
              style={styles.flex}
              type="secondary"
              disabled={disabledButton}
              onPress={() =>
                navigation.navigate(SCREEN_NAME.EditReportScreen, {
                  expenseReportId: data?.expenseReportId,
                  onRefreshData,
                })
              }
            >
              {t("update_report")}
            </Button>
            <Button
              eventName={EVENT.REPORT.SUBMITTED}
              style={styles.flex}
              onPress={isReportCashAdvance ? handleResubmitReportCashAdvance : handleResubmitReport}
              disabled={disabledButton || !data?.expenses?.length}
            >
              {status === EXPENSE_REPORT_STATUS.DRAFT ? t("submit") : t("re_submit")}
            </Button>
          </View>
        </FloatFooter>
      );
    }
    return <FloatFooter onLayout={onLayoutFooter}>{totalAmountSection}</FloatFooter>;
  };

  useFocusEffect(
    useCallback(() => {
      onFetchReportDetail();
    }, [])
  );

  if (!isLoading && !data) {
    return (
      <View style={styles.errorContainer}>
        <EmptyData
          icon={<ErrorInvoiceIcon />}
          title={t("report_not_found_title")}
          description={t("report_not_found_description")}
        />
      </View>
    );
  }

  return (
    <ReportManageProvider>
      <View style={styles.container}>
        <TopTabLineView renderScene={renderScene} routes={routes} index={index} setIndex={setIndex} />
        {renderFooterButton()}
        <CancelExpenseReportModal
          ref={modalCancelRef}
          reportId={data?.expenseReportId}
          onRefreshData={onRefreshData}
          title={data?.title}
        />
        <RejectExpenseReportModal
          ref={modalRejectRef}
          reportId={data?.expenseReportId}
          onRefreshData={() => onRefreshData(route.params.expenseReportId)}
          title={data?.title}
          data={data}
        />
        <ApproverChooserModal
          isRequired={approverType === ApproverModalType.RE_SUBMIT}
          ref={approverChooserModalRef}
          approverId={approverId}
          onSubmit={approverType === ApproverModalType.RE_SUBMIT ? onValidateBudget : handleApproveReportManual}
          onApproverId={(approverId: string) => setApproverId(approverId)}
          title={approverType === ApproverModalType.RE_SUBMIT ? t("select_approver") : t("select_approver_next")}
          titleSecond={approverType === ApproverModalType.RE_SUBMIT ? t("select_approver") : t("select_approver_next")}
          description={
            approverType === ApproverModalType.RE_SUBMIT
              ? t("select_approver_description")
              : t("description_select_approver")
          }
          confirmText={approverType === ApproverModalType.RE_SUBMIT ? t("send_report") : t("approve")}
        />
        <AlertNotification ref={alertNotificationRef} />
      </View>
    </ReportManageProvider>
  );
};

export default withTrackingSession(ReportDetailScreen, SCREEN_NAME.ReportDetailScreen);

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale05 },
  footerButtonLeft: { flex: 1, marginRight: 6 },
  totalAmountContainer: { marginBottom: 7 },
  footerButtonContainer: { flexDirection: "row", justifyContent: "space-between", gap: 8 },

  flex: { flex: 1 },
  descriptionItem: { flexDirection: "row", gap: 8 },
  dotIcon: { width: 5, height: 5, borderRadius: 3, backgroundColor: Colors.grayscale100, marginTop: 6 },
  errorContainer: { backgroundColor: Colors.white, paddingTop: 70, flex: 1 },
});
