import { ApolloClient, from, InMemoryCache, Observable } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Device from "expo-device";

import * as authClient from "app/utils/authClient";
import { Keys } from "constants/Keys";
import createHttpLink from "./http-link";
import AuthHelper from "utils/authHelper";
import app from "../../app.json";
import { sentryService } from "services/SentryService";
import { getCurrentCompanyId } from "utils";

const cache = new InMemoryCache({
  typePolicies: {
    invoices: {
      keyFields: ["invoice_id"],
    },
    invoice_items: {
      keyFields: ["invoice_item_id"],
    },
    invoice_validations: {
      keyFields: ["invoice_validation_id"],
    },
    invoice_attachments: {
      keyFields: ["invoice_attachment_id"],
    },
    invoice_orders: {
      keyFields: ["invoice_order_id"],
    },
    companies: {
      keyFields: ["company_id"],
    },
    users: {
      keyFields: ["user_id"],
    },
    groups: {
      keyFields: ["group_id"],
    },
    users_groups: {
      keyFields: ["user_group_id"],
    },
    group_settings: {
      keyFields: ["group_setting_id"],
    },
    invoice_tags: {
      keyFields: ["company_id"],
    },
    doc_sequences: {
      keyFields: ["doc_sequence_id"],
    },
    emails: {
      keyFields: ["email_id"],
    },
    company_partners: {
      keyFields: ["company_partner_id"],
    },
    company_partner_contacts: {
      keyFields: ["company_partner_contact_id"],
    },
    company_invitations: {
      keyFields: ["company_invitation_id"],
    },
    company_partner_bank_accounts: {
      keyFields: ["company_partner_bank_account_id"],
    },
    invops_item_matchings: {
      keyFields: ["item_matching_id"],
    },
    invops_header_matchings: {
      keyFields: ["header_matching_id"],
    },
    invops_matching_facts: {
      keyFields: ["matching_fact_id"],
    },
    invops_matching_results: {
      keyFields: ["matching_result_id"],
    },
    invops_goods_receipts: {
      keyFields: ["goods_receipt_id"],
    },
    invops_goods_receipt_items: {
      keyFields: ["goods_receipt_item_id"],
    },
    invops_purchase_orders: {
      keyFields: ["purchase_order_id"],
    },
    invops_purchase_order_items: {
      keyFields: ["purchase_order_item_id"],
    },
    invops_group_headers: {
      keyFields: ["group_header_id"],
    },
    invops_group_header_matchings: {
      keyFields: ["group_header_matching_id"],
    },
    invops_payment_requests: {
      keyFields: ["payment_request_id"],
    },
    invops_cash_vouchers: {
      keyFields: ["cash_voucher_id"],
    },
    invops_payment_requests_purchase_orders: {
      keyFields: ["payment_requests_purchase_orders_id"],
    },
    invops_cash_vouchers_purchase_orders: {
      keyFields: ["cash_vouchers_purchase_orders_id"],
    },
    id_user_mfa_settings: {
      keyFields: ["user_mfa_setting_id"],
    },
    permissions: {
      keyFields: ["permission_id"],
    },
    roles: {
      keyFields: ["role_id"],
    },
    users_roles: {
      keyFields: ["users_roles_id"],
    },
    roles_permissions: {
      keyFields: ["roles_permissions_id"],
    },
    expense_policies: {
      keyFields: ["expense_policy_id"],
    },
    expense_policies_employees: {
      keyFields: ["expense_policies_employees_id"],
    },
    company_employees: {
      keyFields: ["employee_id"],
    },
    expense_reports: {
      keyFields: ["expense_report_id"],
    },
    expense_approval_rules: {
      keyFields: ["expense_approval_rule_id"],
    },
    expense_approval_rule_employees: {
      keyFields: ["expense_approval_rules_employees_id"],
    },
    expense_travels: {
      keyFields: ["expense_travel_id"],
    },
    expense_requests: {
      keyFields: ["expense_request_id"],
    },
    expense_approval_logs: {
      keyFields: ["expense_approval_log_id"],
    },
    expense_request_attachments: {
      keyFields: ["expense_request_attachment_id"],
    },
    expense_allowances: {
      keyFields: ["expense_allowance_id"],
    },
    expense_category: {
      keyFields: ["expense_category_id"],
    },
    expense_attachments: {
      keyFields: ["expense_attachment_id"],
    },
    locations: {
      keyFields: ["location_id"],
    },
    custom_field_values: {
      keyFields: ["object_id"],
    },
    expense_request_category: {
      keyFields: ["expense_request_category_id"],
    },
    company_departments: {
      keyFields: ["department_id"],
    },
  },
});

const resetToken = onError(({ networkError, graphQLErrors, operation, forward }) => {
  const graphQLInvalidJwt = graphQLErrors?.[0]?.extensions?.code === "invalid-jwt";

  const networkError401 =
    networkError && networkError.name === "ServerError" && (networkError as any)?.statusCode === 401;

  if (graphQLInvalidJwt || networkError401) {
    return new Observable((observer) => {
      authClient
        .renewToken()
        .then(({ token }) => {
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${token}`,
            },
          });
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };
          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          // No refresh or client token available, we force user to login
          observer.error(error);

          __DEV__ && console.log(error);
          AuthHelper.getInstance().logout();
          sentryService.captureException(graphQLErrors);
        });
    });
  }
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const bizziToken = await AsyncStorage.getItem(Keys.token);
  const companyId = await getCurrentCompanyId();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "client-name": "bizzi-expense",
      "client-version": app?.expo?.version,
      "client-code-version": process.env.GIT_COMMIT_SHA,
      "client-device": JSON.stringify({
        isDevice: Device.isDevice,
        osName: Device.osName,
        osVersion: Device.osVersion,
        platformApiLevel: Device.platformApiLevel,
      }),
      "X-Company-Id": companyId ?? "",
      ...(bizziToken ? { Authorization: bizziToken ? `Bearer ${bizziToken}` : "" } : {}),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]\nMessage: ${message}\nLocation: ${JSON.stringify(locations, null, 2)}\nPath: ${path}`
      );
    });
    sentryService.captureException(graphQLErrors);
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createHttpLink();

const links = __DEV__ ? from([authLink, errorLink, resetToken, httpLink]) : from([authLink, resetToken, httpLink]);

export default () => {
  return new ApolloClient({
    ssrMode: false,
    connectToDevTools: __DEV__,
    link: links,
    name: "Expense-app",
    version: "1",
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
    },
  });
};
