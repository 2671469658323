import { Platform, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import React, { ReactNode, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import { BottomSheetBackdrop, BottomSheetModal, BottomSheetModalProps } from "@gorhom/bottom-sheet";

import HeaderBottomSheet from "./HeaderBottomSheetModal";
import useBottomSheetBackHandler from "./useBottomSheetBackHandler";
import { useWindowDimensions } from "components";
import { useReducedMotion } from "react-native-reanimated";

export interface BottomSheetModalCustomProps extends BottomSheetModalProps {
  snapPoints: string[] | number[];
  title?: string;
  description?: string;
  enableTouchOutsideToClose?: boolean;
  showHeader?: boolean;
  goBackIsDismiss?: boolean;
  renderDescription?: () => ReactNode;
}

export interface BottomSheetModalCustomMethods {
  present: (params?: any) => void;
  close?: () => void;
  expand?: () => void;
}
const BottomSheetModalCustom = React.forwardRef<BottomSheetModalCustomMethods, BottomSheetModalCustomProps>(
  (
    {
      snapPoints,
      title,
      description,
      children,
      enableTouchOutsideToClose = true,
      showHeader = true,
      goBackIsDismiss = false,
      renderDescription,
      onDismiss,
      ...rest
    },
    ref
  ) => {
    // avoid issue: not showing modal when user enable 'Reduce Motion' on IOS, Android and Webapp
    const reducedMotion = useReducedMotion();

    const { width } = useWindowDimensions();
    const { bottom, top } = useSafeAreaInsets();
    const bottomSheetRef = useRef<BottomSheetModal>(null);
    const isOpened = useRef<boolean>(false);

    const { handleSheetPositionChange } = useBottomSheetBackHandler(bottomSheetRef);

    useImperativeHandle(
      ref,
      () => ({
        present: () => {
          isOpened.current = true;
          bottomSheetRef?.current?.present?.();
        },
        close: () => {
          if (reducedMotion) {
            bottomSheetRef?.current?.snapToPosition(0);
          } else {
            bottomSheetRef?.current?.dismiss();
          }
        },
      }),
      [reducedMotion]
    );
    useEffect(() => {
      if (Platform.OS === "web") {
        const handleKeyDown = (event) => {
          if (event.key === "Escape" && isOpened.current) {
            bottomSheetRef?.current?.close();
          }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }
    }, []);
    const renderBackdrop = useCallback(
      (props) => (
        <BottomSheetBackdrop
          {...props}
          disappearsOnIndex={-1}
          appearsOnIndex={0}
          opacity={0.3}
          pressBehavior={enableTouchOutsideToClose ? "close" : "none"}
        />
      ),
      [enableTouchOutsideToClose]
    );

    const renderHeaderHandle = useCallback(
      (props) => {
        return showHeader ? (
          <HeaderBottomSheet
            title={title}
            description={description}
            renderDescription={renderDescription}
            onClose={() => bottomSheetRef?.current?.close()}
            {...props}
          />
        ) : null;
      },
      [showHeader, title, description, renderDescription]
    );
    const renderFooter = () => <View style={{ paddingBottom: bottom || 15 }} />;

    const handleOnDismiss = () => {
      isOpened.current = false;
      onDismiss?.();
    };
    return (
      <BottomSheetModal
        topInset={top ? top + 5 : 30}
        containerStyle={[styles.containerStyle, { maxWidth: width }]}
        onChange={(index) => handleSheetPositionChange(index, goBackIsDismiss)}
        handleIndicatorStyle={styles.handleIndicatorStyle}
        snapPoints={snapPoints}
        handleComponent={renderHeaderHandle}
        footerComponent={renderFooter}
        animateOnMount={!reducedMotion}
        enableHandlePanningGesture={false}
        backdropComponent={renderBackdrop}
        style={styles.modal}
        onDismiss={handleOnDismiss}
        {...rest}
        ref={bottomSheetRef}
      >
        {children}
      </BottomSheetModal>
    );
  }
);
export default React.memo(BottomSheetModalCustom);

const styles = StyleSheet.create({
  containerStyle: {
    width: "100%",

    marginLeft: "auto",
    marginRight: "auto",
  },
  handleIndicatorStyle: {
    display: "none",
  },
  modal: {
    borderWidth: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
});
