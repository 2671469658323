import { createContext, Fragment, useContext, useRef, useState, useMemo } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { AppText, BottomSheetModalCustomMethods, BottomSheetScrollViewModalCustom, Button, Line } from "components";
import { navigate, replace } from "navigation/RootNavigation";
import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import { CONSTANTS, REQUEST_TYPE } from "constants/constants";
import { ActionItem } from "screens/StartGuideScreen/ActionItemDetailScreen/ActionItemDetailScreen";
import { Colors, Fonts } from "theme";
import { ExpenseRequestListTabs } from "screens/ExpenseRequest/types";
import { REPORT_LIST_TAB } from "screens/Report/ReportListScreen/ReportListScreen";
import { EXPENSE_LIST_TAB } from "screens/Expense/ExpenseListScreen/ExpenseListScreen";
import { useCopilot } from "react-native-copilot";
import { useTranslation } from "react-i18next";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

export enum GuideVideoActionCode {
  APPROVE_FROM_LIST = "approve_from_list",
  APPROVE_QUICKLY = "approve_quickly",
}
type ChildrenAction = {
  title: string;
  isCompleted: boolean;
};
export type ChildrenActionVideo = {
  code: GuideVideoActionCode;
  type: "video";
  videoUrl: string;
} & ChildrenAction;
export type ChildrenActionWalkthrough = {
  type: "walkthrough";
  walkthroughName: string;
} & ChildrenAction;

export type BaseActionItem = {
  code?: string;
  title: string;
  childrenActions: (ChildrenActionVideo | ChildrenActionWalkthrough)[];
};

const GUIDE_ACITONS = (t): BaseActionItem[] => [
  {
    title: t("walkthrough_overview_title"),
    childrenActions: [
      {
        title: t("walkthrough_overview_description"),
        isCompleted: false,
        type: "walkthrough",
        walkthroughName: CONSTANTS.WALK_THROUGH.OVERVIEW.NAME,
      },
    ],
  },
  {
    code: REQUEST_TYPE.other,
    title: t("expense_request_walkthrough"),
    childrenActions: [
      {
        title: t("view_category_policy"),
        isCompleted: false,
        type: "walkthrough",
        walkthroughName: CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.NAME,
      },
      {
        title: t("create_expense_request"),
        isCompleted: false,
        type: "walkthrough",
        walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_EXPENSE_REQUEST.NAME,
      },
    ],
  },
  {
    code: REQUEST_TYPE.travel,
    title: t("travel_request_walkthrough"),
    childrenActions: [
      {
        title: t("view_travel_policy"),
        isCompleted: false,
        type: "walkthrough",
        walkthroughName: CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME,
      },
      {
        title: t("create_travel_request"),
        isCompleted: false,
        type: "walkthrough",
        walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME,
      },
    ],
  },
  {
    title: t("report_walkthrough"),
    childrenActions: [
      {
        title: t("view_invoice_email"),
        isCompleted: false,
        type: "walkthrough",
        walkthroughName: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME,
      },
      {
        title: t("create_expense"),
        isCompleted: false,
        type: "walkthrough",
        walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME,
      },
      {
        title: t("create_report"),
        isCompleted: false,
        type: "walkthrough",
        walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME,
      },
    ],
  },
  {
    title: t("approval_documents"),
    childrenActions: [
      {
        code: GuideVideoActionCode.APPROVE_FROM_LIST,
        title: t("approval_from_list"),
        isCompleted: false,
        type: "video",
        videoUrl: "https://bizzi.app.link/guide-for-approval-from-requests-reports",
      },
      {
        code: GuideVideoActionCode.APPROVE_QUICKLY,
        title: t("approval_from_home_screen"),
        isCompleted: false,
        type: "video",
        videoUrl: "https://bizzi.app.link/guide-for-approval-home-screen",
      },
    ],
  },
];

interface IWalkthroughGuideContext {
  currentChildrenActionCode?: string;
  handleFinishGuideAction: () => void;
  handleSkipGuideAction: (params: { stepNumber: number; stepTitle: string; totalStep: number }) => void;
  currentAction?: BaseActionItem;
  setCurrentAction: (action: BaseActionItem) => void;
  getActionItemsData: () => Promise<BaseActionItem[]>;
  markCompletedGuideAction: () => void;
  startWalkthrough: (walkthroughName: string) => void;
  setCurrentChildrenActionCode?: (actionCode: string) => void;
}

const WalkthroughGuideContext = createContext<IWalkthroughGuideContext>({} as IWalkthroughGuideContext);

WalkthroughGuideContext.displayName = "WalkthroughGuideContext";
const CURRENT_ACTION_CODE_KEY = "UserGuideContextActionKeyWebStorage";
const CURRENT_ACTION_KEY = "UserGuideContextActionsWebStorage";

function WalkthroughGuideProvider({ children }) {
  const { t } = useTranslation("app/contexts/WalkthroughGuideContext");
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>();

  const [currentChildrenActionCode, setCurrentChildrenActionCode] = useState(
    Platform.OS === "web" ? sessionStorage.getItem(CURRENT_ACTION_CODE_KEY) : ""
  );
  const [currentAction, setCurrentAction] = useState<BaseActionItem>(
    Platform.OS === "web" ? (JSON.parse(sessionStorage.getItem(CURRENT_ACTION_KEY)) as any) : null
  );
  const currentChildrenActionCodeRef = useRef(
    Platform.OS === "web" ? sessionStorage.getItem(CURRENT_ACTION_CODE_KEY) : ""
  );
  const currentActionRef = useRef(
    Platform.OS === "web" ? (JSON.parse(sessionStorage.getItem(CURRENT_ACTION_KEY)) as any) : null
  );
  const handleSetCurrentChildrenActionCode = (code) => {
    setCurrentChildrenActionCode(code);
    currentChildrenActionCodeRef.current = code;
    if (Platform.OS === "web") {
      sessionStorage.setItem(CURRENT_ACTION_CODE_KEY, code);
    }
  };
  const handleSetCurrentAction = (action: BaseActionItem) => {
    setCurrentAction(action);
    currentActionRef.current = action;
    if (Platform.OS === "web") {
      sessionStorage.setItem(CURRENT_ACTION_KEY, JSON.stringify(action));
    }

    console.log("action", action);
  };
  const getActionItemsData = async (): Promise<BaseActionItem[]> => {
    const completedGuideIdsString = await AsyncStorage.getItem(Keys.COMPLETED_GUIDE_IDS);
    const completedGuideIds = completedGuideIdsString ? JSON.parse(completedGuideIdsString) : [];
    return GUIDE_ACITONS(t).map((actionItem) => {
      const updatedChildrenActions = actionItem.childrenActions.map((childrenAction) => {
        const guideActionCode = childrenAction?.type === "video" ? childrenAction.code : childrenAction.walkthroughName;
        if (completedGuideIds.includes(guideActionCode)) {
          return { ...childrenAction, isCompleted: true };
        } else {
          return { ...childrenAction, isCompleted: false };
        }
      });
      return { ...actionItem, childrenActions: updatedChildrenActions };
    });
  };

  const markCompletedGuideAction = async ({
    action = currentAction as BaseActionItem,
    childrenActionCode = currentChildrenActionCode,
  } = {}) => {
    const completedGuideIdsString = await AsyncStorage.getItem(Keys.COMPLETED_GUIDE_IDS);
    const completedGuideIds = completedGuideIdsString ? JSON.parse(completedGuideIdsString) : [];
    if (!completedGuideIds.includes(childrenActionCode)) {
      completedGuideIds.push(childrenActionCode);
      const updatedCompletedGuideIdsString = JSON.stringify(completedGuideIds);
      await AsyncStorage.setItem(Keys.COMPLETED_GUIDE_IDS, updatedCompletedGuideIdsString);
    }
    const newCurrentAction = action?.childrenActions?.map((item) => {
      if (
        (item?.type === "video" && item?.code === childrenActionCode) ||
        (item?.type === "walkthrough" && item?.walkthroughName === childrenActionCode)
      ) {
        return { ...item, isCompleted: true };
      }
      return item;
    });
    if (newCurrentAction) {
      handleSetCurrentAction({ ...currentAction, childrenActions: newCurrentAction });
    }
  };
  const handleGoBack = () => {
    bottomSheetRef?.current?.close();
    let isNavigateToStartGuideScreen = false;
    switch (currentChildrenActionCode) {
      case CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME:
      case CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME:
      case CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME: {
        isNavigateToStartGuideScreen = true;
        replace(SCREEN_NAME.StartGuideScreen);
        break;
      }
      case CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME: {
        break;
      }
    }
    if (!isNavigateToStartGuideScreen) {
      navigate(SCREEN_NAME.StartGuideScreen);
    }
  };
  const handleSkipGuideAction = ({ stepNumber, stepTitle, totalStep }) => {
    if (currentActionRef.current) {
      bottomSheetRef.current.present();
      markCompletedGuideAction({
        action: currentActionRef.current,
        childrenActionCode: currentChildrenActionCodeRef.current,
      });
      //tracking skip event
      const currentActionChildren = currentActionRef.current.childrenActions.find(
        (item) => item.type === "walkthrough" && item.walkthroughName === currentChildrenActionCodeRef.current
      );
      analyticService.logEvent({
        name: EVENT.WALKTHROUGH.SKIP_ONBOARDING_COURSE,
        properties: {
          course_name: currentActionRef.current.title,
          action_name: currentActionChildren?.title,
          current_step_title: stepTitle,
          current_step_number: stepNumber,
          total_step: totalStep,
        },
      });
    }
  };
  const handleFinishGuideAction = () => {
    if (currentAction) {
      bottomSheetRef.current.present();
      markCompletedGuideAction();
    }
  };
  const currentActionIndex = useMemo(() => {
    if (!currentAction?.childrenActions) {
      return 0;
    }
    const foundIndexAction = currentAction.childrenActions.findIndex(
      (item) => item.type === "walkthrough" && item.walkthroughName === currentChildrenActionCode
    );
    return foundIndexAction;
  }, [currentAction]);

  const nextAction = (
    currentAction?.childrenActions?.[currentActionIndex + 1] &&
    !currentAction.childrenActions[currentActionIndex + 1].isCompleted
      ? currentAction.childrenActions[currentActionIndex + 1]
      : undefined
  ) as ChildrenActionWalkthrough;

  const handleContinue = () => {
    bottomSheetRef?.current?.close();
    startWalkthrough(nextAction.walkthroughName);
  };

  const startWalkthrough = (walkthroughName: string) => {
    handleSetCurrentChildrenActionCode(walkthroughName);
    switch (walkthroughName) {
      case CONSTANTS.WALK_THROUGH.OVERVIEW.NAME: {
        navigate(SCREEN_BOTTOM_TAB.Home, {
          shouldShowWalkthrough: true,
          walkthroughName: CONSTANTS.WALK_THROUGH.OVERVIEW.NAME,
        } as any);
        break;
      }
      case CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME: {
        navigate(SCREEN_BOTTOM_TAB.RequestScreen, {
          shouldShowWalkthrough: true,
          walkthroughType: REQUEST_TYPE.travel,
          tab: ExpenseRequestListTabs.MINE,
        } as any);
        break;
      }
      case CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.NAME: {
        navigate(SCREEN_BOTTOM_TAB.Home, {
          shouldShowWalkthrough: true,
          walkthroughName: CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.NAME,
        } as any);
        break;
      }
      case CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME: {
        navigate(SCREEN_BOTTOM_TAB.Home, {
          shouldShowWalkthrough: true,
          walkthroughName: CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME,
        } as any);
        break;
      }
      case CONSTANTS.WALK_THROUGH.CREATE_EXPENSE_REQUEST.NAME: {
        navigate(SCREEN_BOTTOM_TAB.RequestScreen, {
          walkthroughType: REQUEST_TYPE.other,
          tab: ExpenseRequestListTabs.MINE,
          shouldShowWalkthrough: true,
        } as any);
        break;
      }
      case CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME: {
        navigate(SCREEN_BOTTOM_TAB.ReportsScreen, {
          tab: REPORT_LIST_TAB.MY_REQUEST,
          shouldShowWalkthrough: true,
        } as any);
        break;
      }
      case CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME: {
        navigate(SCREEN_BOTTOM_TAB.ExpensesScreen, {
          tab: EXPENSE_LIST_TAB.NEW,
          shouldShowWalkthrough: true,
        } as any);
        break;
      }
      case CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME: {
        navigate(SCREEN_BOTTOM_TAB.Home, {
          shouldShowWalkthrough: true,
          walkthroughName: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME,
        } as any);
        break;
      }
    }
    return;
  };

  return (
    <WalkthroughGuideContext.Provider
      value={{
        handleFinishGuideAction,
        handleSkipGuideAction,
        currentChildrenActionCode,
        currentAction,
        setCurrentAction: handleSetCurrentAction,
        getActionItemsData,
        markCompletedGuideAction,
        startWalkthrough,
        setCurrentChildrenActionCode: handleSetCurrentChildrenActionCode,
      }}
    >
      {children}
      <BottomSheetScrollViewModalCustom
        wrapperByScrollView={false}
        ref={bottomSheetRef}
        title={t("guide_title")}
        containerStyle={{ paddingTop: 10, paddingBottom: 120 }}
        renderDescription={() => (
          <AppText style={Fonts.BodySmall} color={Colors.grayscale80}>
            {currentAction?.title}
          </AppText>
        )}
        renderFooter={() => (
          <View style={styles.footerButton}>
            <Button style={styles.flex} type={nextAction ? "secondary" : "primary"} onPress={handleGoBack}>
              {t("back_guide")}
            </Button>
            {nextAction && (
              <Button style={styles.flex} onPress={handleContinue}>
                {t("continue")}
              </Button>
            )}
          </View>
        )}
      >
        <View>
          {currentAction?.childrenActions?.map((item, index) => (
            <Fragment key={index}>
              <ActionItem index={index} item={item} disabled={true} />
              <Line />
            </Fragment>
          ))}
        </View>
      </BottomSheetScrollViewModalCustom>
    </WalkthroughGuideContext.Provider>
  );
}

function useWalkthroughGuide(): IWalkthroughGuideContext {
  const context = useContext(WalkthroughGuideContext);
  if (context === undefined) {
    throw new Error(`useWalkthroughGuide must be used within a WalkthroughGuideProvider`);
  }
  return context;
}

export { WalkthroughGuideProvider, useWalkthroughGuide };

const styles = StyleSheet.create({
  footerButton: { flexDirection: "row", gap: 8 },
  flex: { flex: 1 },
});
